export const libellesType = new Map([
    ["activite", "Activité"],
    ["manifestation", "Manifestation"],
    ["residenceSociale", "Résidence Sociale/Hotel Social"],
    ["immoVente", "Immo. Vente"],
    ["appartement", "Appartement"],
    ["personneResSoc", "Personnes Res. Sociale"],
    ["localExploitation", "Local d'exploitation"],
    ["peniche", "Péniche"]

])

export const choixTypeLocal = [
    { label: "Bungalow", value: "bungalow" },
    { label: "Entrepôts", value: "entrepots" },
    { label: "Centre de distribution", value: "centreDistribution" },
    { label: "Centre d'activités", value: "centreActivites" },
    { label: "Relais", value: "relais" },
    { label: "Relais Bébés", value: "relaisBebe" },
    { label: "Ateliers / Jardin", value: "ateliersJardin" },            
    { label: "Bureau", value: "bureau" },
    { label: "Garage", value: "garage" },
    { label: "Siège social", value: "siege" },
    { label: "Autre", value: "autre" }
]



export const ExpressionReguliere = {
    entier: /^[0-9]$/
}


export const listeTypeManifestation = [
    { label: 'Loto ', value: 'loto' },
    { label: 'Paquets cadeaux', value: 'paqCadeaux' },
    { label: 'Arbre de noël', value: 'noel' },
    { label: 'Réunion', value: 'reunion' },
    { label: 'Spectacle musical ouvert au public', value: 'musicPublic' },
    { label: 'Spectacle musical pour les bénéficiaires', value: 'musicBen' },
    { label: 'Vide-grenier / brocante', value: 'brocante' },
    { label: 'Sortie culturelle, visite', value: 'visiteCulturelle' },
    { label: 'Soirée dansante', value: 'soireeDansante' },
    { label: 'Randonnée pédestre', value: 'rando' },
    { label: 'Epreuves sportives', value: 'sport' },
    { label: 'Balade moto', value: 'moto' },
    { label: 'Vente aux enchères', value: 'enchere' },
    { label: 'Théatre', value: 'teatre'},
    { label: "Repas des bénévoles",value:"repasBenevoles"},
    { label: "Concours de belote", value:"belote"},
    { label: "Atelier cuisine",value:"atelScol"}
].sort((a,b)=>(a.label > b.label) ? 1 : -1);

listeTypeManifestation.push({ label: 'Autre', value: 'autre' });

export const Activites = [
    {value:"accPers",libelle:"Accueil de personnes / bébés",peniche:1,autre:1},
    {value:"atelInfo",libelle:"Atelier internet, informatique",peniche:1,autre:1},
    {value:"accJour",libelle:"Accueil de jour",peniche:1,autre:1},
    {value:"atelCoif",libelle:"Atelier coiffure",peniche:1,autre:1},
    {value:"atelCuis",libelle:"Atelier cuisine",peniche:1,autre:1},
    {value:"prepRepas",libelle:"Préparation de repas",peniche:1,autre:1},
    {value:"atelScol",libelle:"Atelier français et/ou accompagnement scolaire",peniche:1,autre:1},
    {value:"cine",libelle:"Cinéma",peniche:1,autre:2},
    {value:"collecte",libelle:"Collecte de denrées, vêtements, produits d'hygiène",peniche:1,autre:1},
    {value:"conseilBudget",libelle:"Conseil pour le budget, l'emploi, accès aux droits, banque",peniche:1,autre:1},
    {value:"atelCouture",libelle:"Atelier couture",peniche:1,autre:1},
    {value:"distribution",libelle:"Distribution de denrées",peniche:1,autre:1},
    {value:"atelEstime",libelle:"Atelier estime de soi",peniche:1,autre:1},
    {value:"maraude",libelle:"Maraude",peniche:1,autre:1},
    {value:"collecteNat",libelle:"Collecte nationale",peniche:1,autre:1},
    {value:"vacances",libelle:"Séjour, vacances",peniche:2,autre:1},
    {value:"art",libelle:"Artistique non sport (dessin, peinture etc.)",peniche:1,autre:1},
    {value:"culture",libelle:"Culturelle hors cinema",peniche:1,autre:1},
    {value:"distribIti",libelle:"Distribution itinérante de denrées",peniche:1,autre:1},
    {value:"sortieCulturelle",libelle:"Sortie culturelle",peniche:1,autre:1},    
    {value:"hebergeUrgence",libelle:"Hébergement d'urgence",peniche:1,autre:false},
    {value:"conseilJuridique",libelle:"conseil juridique en bus",peniche:1,autre:1},
    {value:"sport",libelle:"Activité sportive",peniche:2,autre:1},
    {value:"autre",libelle:"Autre activité",peniche:1,autre:1},
    {value:"theatre",libelle:"Théâtre",peniche:1,autre:1}
]


export const facturerOui = 'Facturer';
export const facturerNon = 'Ne pas facturer';

Date.prototype.isValid = function(){
    return this.getTime() === this.getTime();
}

export const dateEnFrancais = (date, defaut=null, addDays=null)=>{
    if(!date) return defaut;
    const objDate = new Date(date);
    if(addDays!==null){
        objDate.setDate(objDate.getDate()+addDays);
    }
    return objDate.isValid() ? objDate.toLocaleDateString() : defaut;
}