import React from 'react';
//import { decorate, observable, action, computed, reaction, ObservableMap } from 'mobx';
import RisqueStore from '../../store/Risque';
import ImmoVente from './index';
/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class ResidenceSocialeStore extends RisqueStore {

    constructor(idAssociation){
        super(idAssociation);
        this.idAssociation=idAssociation;
        this.type = "immoVente";
        this.contrat = "L001";
        this.intercalaire = "10";        
        this.composant = <ImmoVente/>
        this.informations = {  
            dateDebut: null,
            dateFin: null,                                  
            adresse: "",
            cp:"",
            commune:"",
            typeDeBien:null,                        
            superficie:"",
            batimentGrand:null,
            batimentClasse:null                               
        }
        this.messageAttenteAcceptation = "Transmettre l’attestation du notaire avec le descriptif des locaux";
        //this.contrat=null;
        //this.intercalaire=null;

    }

    setAcceptation() {
        const {superficie,batimentClasse,batimentGrand,typeDeBien} = this.informations;
        if(Number(superficie)>2500 || batimentGrand || batimentClasse || typeDeBien === "chateau" || typeDeBien === "autre"){
            this.acceptation = null;
        } else {
            this.acceptation = true;
        }
    }    

}

export default ResidenceSocialeStore;