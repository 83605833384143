import React from 'react';
import { decorate, computed, observable } from 'mobx';
import RisqueStore from '../../store/Risque';
import Activite from './index';
import { Activites } from '../../Constantes';

/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class ActiviteStore extends RisqueStore {

    constructor(idAssociation){
        super(idAssociation);

        this.listeActivites = Activites.filter( a => idAssociation === "PNCH" && a.peniche!== false || idAssociation !== "PNCH" && a.autre!== false )
                                       .map( a => { return {label:a.libelle,value:a.value,intercalaire: idAssociation === "PNCH" ? a.peniche : a.autre }});

        this.idAssociation=idAssociation;
        this.type = "activite";
        this.contrat = "S001";        
        this.composant = <Activite/>
        this.acceptation = null;
        this.informations = {
            dateDebut: null,
            dateFin: null,
            natureActivite: null,
            libelle:null,
            occupants:[]
        }
        //this.contrat=null;
        //this.intercalaire=null;

    }

    setIntercalaire() {
        const activite = this.listeActivites.find( a => a.value === this.informations.natureActivite );
        this.intercalaire = activite ? activite.intercalaire : 1;
    }

    setAcceptation() {
        this.acceptation = this.informations.natureActivite !== "autre" ? true : null;
    }



}



export default ActiviteStore;