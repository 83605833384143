import React from 'react';
import { facturerOui, facturerNon, dateEnFrancais } from '../../../Constantes';


export class AssociationsDep extends React.Component {


    render() {
        if(!this.props.risques || this.props.risques.length===0) return null
        return (
            <React.Fragment>
                <tr>
                    <th className='nom-risque' colSpan="16">Assurer une activité</th>
                </tr>
                <tr>
                    <th>N° Assuré</th>
                    <th>N° Contrat</th>
                    <th>N° Intercalaire</th>
                    <th>ID Asso</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Nature activité</th>
                    <th colSpan="8"></th>
                    <th className='facturation'></th>
                </tr>
                {this.props.risques.map((risque, i) => <tr key={i}>
                    <td>{risque.noSoc}</td>
                    <td>{risque.contrat}</td>
                    <td>{risque.intercalaire}</td>
                    <td>{risque.idAssociation}</td>
                    <td>{ dateEnFrancais(risque.dateDebut) }</td>
                    <td>{ this.props.mois === risque.moisSortieFacturation && dateEnFrancais(risque.dateFin)}</td>
                    <td>{risque.sportive}</td>
                    <td colSpan="8"></td>
                    <td className='facturation'>{risque.facturer ? facturerOui : facturerNon }</td>
                </tr>)}
            </React.Fragment>
        )
    }


}