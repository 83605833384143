import React from 'react';

import { observer, inject } from 'mobx-react';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { InputSwitch } from 'primereact/inputswitch';

export const SaisieRisque = inject("store")(
    observer(({ store }) => store.risqueStore ?
        <React.Fragment>
            {store.risqueStore.composant}
            {store.risqueStore.champsManquants.size === 0 &&
                <React.Fragment>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <span>{store.risqueStore.acceptation === null ? 'Attente acceptation' : store.risqueStore.acceptation === false ? 'Risque Refusé' : 'Risque Accepté'}</span>
                    </div>
                    {store.risqueStore.messageAttenteAcceptation > "" && store.risqueStore.acceptation === null &&
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', color: 'red' }}>
                            <span>{store.risqueStore.messageAttenteAcceptation}</span>
                        </div>
                    }
                </React.Fragment>
            }

            {store.risqueStore.messageSystematique > "" &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', color: 'red' }}>
                    <span>{store.risqueStore.messageSystematique}</span>
                </div>
            }

            {store.risqueStore.champsManquants.size > 0 &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', color: 'red' }}>
                    <span>Le formulaire est incomplet</span>
                </div>
            }
            {store.risqueStore.messageSupplementaire() > "" &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', color: 'red' }}>
                    <span>{store.risqueStore.messageSupplementaire()}</span>
                </div>
            }
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {(store.risqueStore.id === null || store.user.type === "MACIF") && store.user.role !== 'lecteur' &&
                    <Button label="Enregistrer"
                        icon="pi pi-save"
                        onClick={() => store.save()}
                        disabled={!store.risqueStore.enregistrable ? "disabled" : ""}
                        title={!store.risqueStore.enregistrable ? "Le formulaire est incomplêt" : store.user.type === "MACIF" ? "La modification d'un risque ne sera plus disponible une fois la saisie du stock terminée" : ""} />
                }
                {store.risqueStore.id !== null &&
                    <React.Fragment>
                        {store.user.role !== "lecteur" &&
                            <Button label="Résilier"
                                style={{ marginLeft: '20px' }}
                                icon="pi pi-trash"
                                onClick={() => { if (window.confirm("Voulez-vous vraiment résilier ?")) store.resilier() }}
                                disabled={store.risqueStore.get('dateFin') ? '' : "disabled"}
                                title={store.risqueStore.get('dateFin') ? 'Enregistrer la résiliation au ' + new Date(store.risqueStore.get('dateFin')).toLocaleDateString() : "Il faut d'abord saisir la date de fin"}
                            />
                        }

                        {store.user && store.user.type === "MACIF" && store.user.role !== "lecteur" &&
                            <Button label="Supprimer" style={{ marginLeft: '20px' }} icon="pi pi-trash" onClick={() => { if (window.confirm("Voulez-vous vraiment supprimer ce risque ?")) store.delete() }} />
                        }
                        <Button label="Quitter" style={{ marginLeft: '20px' }} icon="pi pi-times" onClick={() => store.retour()} />
                    </React.Fragment>
                }
                {store.risqueStore.id === null &&
                    <Button label="Annuler" style={{ marginLeft: '20px' }} icon="pi pi-times" onClick={() => store.retour()} />
                }
            </div>
            {store.risqueStore.commentaireMacif > '' &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
                    <Editor style={{ height: '200px', width: '800px' }} 
                            value={store.risqueStore.commentaireMacif} 
                            readOnly={true} headerTemplate={<span>Commentaire Macif</span>} />
                </div>
            }
            { store.user.type === "MACIF"  &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
                    <Editor style={{ height: '200px', width: '800px' }} 
                            value={store.risqueStore.commentaireGestion} 
                            readOnly={store.user.role === "lecteur"} 
                            onTextChange={(e) => store.risqueStore.setCommentaireGestion( e.textValue)}
                            headerTemplate={<span>Commentaire Gestion interne MACIF</span>} />
                </div>
            }            
            {store.user.type === "MACIF" && store.user.role !== "lecteur" &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '40px' }}>
                    <div>
                        <span style={{ position: 'relative', top: '-7px', marginRight: '10px' }}>
                            Autoriser la saisie d'une fiche incomplète
                        </span>
                        <InputSwitch checked={store.risqueStore.autorisationFicheIncomplete} onChange={(e) => store.risqueStore.setAutorisationFicheIncomplete(e.value)} />
                    </div>
                </div>
            }
        </React.Fragment>
        : null
    )
)


//export default SaisieRisque;
