import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';


const fr = {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
    monthNames: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"]
};

const dateDuJour = new Date()
const annee = dateDuJour.getFullYear()
const anneesNaissance = (annee - 25).toString() + ":" + annee
const anneesDebut = (annee - 2).toString() + ":" + (annee + 1)


function age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const AjouterPersonne = ({ onAjouter, enfant }) => {
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [dateNaissance, setDateNaissance] = useState(null)
    const [modeAjout, setModeAjout] = useState(false)

    if (!modeAjout) {
        return (<div><span className='petitBouton' onClick={() => setModeAjout(true)}>Ajouter</span></div>)
    } else {
        return (<div>
            <InputText placeholder='Nom' value={nom} onChange={e => setNom(e.target.value.toUpperCase())} />
            <InputText placeholder='Prénom' value={prenom} onChange={e => setPrenom(e.target.value.toUpperCase())} />
            {enfant &&
                <Calendar value={dateNaissance}
                    onChange={(e) => setDateNaissance(e.value)}
                    locale={fr} dateFormat="dd/mm/yy"
                    monthNavigator={true} yearNavigator={true}
                    readOnlyInput={true}
                    yearRange={anneesNaissance}
                    //footerTemplate={<div>Footer Content</div>}
                    placeholder='Date de naissance' />
            }
            <Button label='Ajouter' onClick={() => {
                setModeAjout(false)                
                onAjouter({ nom: nom, prenom: prenom, enfant: enfant, dateNaissance: dateNaissance })
            }} disabled={ !(nom && prenom && ( dateNaissance || !enfant) ) } /> &nbsp;
            <Button label='Annuler' onClick={() => setModeAjout(false)} />
        </div>)
    }
}

export default ({ logement, majLogement, annuler, supprimer}) => {


    console.log('logement', logement)

    const [occupants, setOccupants] = useState()
    const [dateDebut, setDateDebut] = useState(logement && logement.dateDebut)

    useEffect(() => {
        if (logement) {
            setOccupants(logement.occupants)
        }
    }, [logement])


    //const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }



    if (!logement) {
        return (null)
    } else if (occupants) {
        const adultes = occupants.filter(occ => !occ.enfant)
        const enfants = occupants.filter(occ => occ.enfant)
        const footer = <div>
            <Button label="Enregistrer" icon="pi pi-check" onClick={()=>majLogement({numero:logement.numero,dateDebut:dateDebut,occupants:occupants})} />
            <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={()=>{if(window.confirm(`Voulez-vous vraiment supprimer ce logement ${occupants.length>0 && 'ainsi que ses occupants'} ?`)){supprimer(logement)}}} />
            <Button label="Annuler" icon="pi pi-times" onClick={annuler} />
        </div>
        return (
            <Dialog header={`Logement ${logement.numero} au ${logement.etage === 0 ? 'RDC' : logement.etage + (logement.etage === 1 ? 'er' : 'eme') + ' étage'}`} footer={footer} visible={true} modal={true} onHide={annuler}>
                <Calendar value={dateDebut}
                    onChange={(e) => setDateDebut(e.value)}
                    locale={fr} dateFormat="dd/mm/yy"
                    monthNavigator={true} yearNavigator={true}
                    readOnlyInput={true}
                    yearRange={anneesDebut}
                    //footerTemplate={<div>Footer Content</div>}
                    placeholder='Date début' />
                <br />
                {adultes &&
                    <React.Fragment>
                        Adutes:<br />
                        <ul>
                            {adultes.map(personne => <li>
                                {personne.nom} {personne.prenom}
                            </li>)
                            }
                        </ul>
                        <AjouterPersonne onAjouter={(p) => setOccupants([...occupants, p])} enfant={false} />
                    </React.Fragment>
                }
                {enfants &&
                    <React.Fragment>
                        Enfants:<br />
                        <ul>
                            {enfants.map(personne => <li>
                                {personne.nom} {personne.prenom} - {personne.dateNaissance.toLocaleDateString()} - {age(personne.dateNaissance)} ans
                            </li>)
                            }
                        </ul>
                        <AjouterPersonne onAjouter={(p) => setOccupants([...occupants, p])} enfant={true} />
                    </React.Fragment>
                }
            </Dialog >
        )
    } else {
        return null
    }
}
