import React from 'react';
import { inject } from 'mobx-react';


import { Button } from 'primereact/button';

import './style.css'

const styles = {
    root: {
        padding: 16
    }
};

export const Login = inject('store')(
    class extends React.Component {

        constructor(props){
            super(props);
            

            this.state = {
                username: '',
                password: ''
            }

            //console.log(this.state);
        }

        render() {
            const ecart = 100;
            const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }
            const {username,password} = this.state;

            //console.log('state',this.state);

            return (
                <div style={styleFlex}>
                <div  class="bloc-accueil" >
                    <h2>Connexion</h2>
                    <fieldset class="formulaire">
                        <label for="email-acces">Email<sup class="obligatoire">*</sup></label>
                        <input type="email" id="email-acces" name="email-acces" required="" autofocus="" value={username} onChange={(e)=>this.setState({username:e.target.value})} />
                        <label for="mdp-acces">
                            Mot de passe<sup class="obligatoire">*</sup>
                        </label>
                        <input type="password" id="mdp-acces" name="mdp-acces" required=""  value={password} onChange={(e)=>this.setState({password:e.target.value})} />
                    </fieldset>
                    <p style={{textAlign:'center'}}>
                        <Button  label="J'accède à mon espace" onClick={(e)=>this.props.store.login(username,password)}/>
                    </p>
                </div>
                </div>
            );
        }

        handleClick = () => {
            window.alert("En cours de développement...")
        };
    }
);