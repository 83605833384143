import { decorate, observable, action, computed, reaction, ObservableMap } from 'mobx';

/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class RisqueStore {

    id=null;
    composant=null;
    idAssociation= null;
    noSoc=9303985;
    contrat=null;
    intercalaire=null;
    type=null;
    dateDebut=null;
    dateFin=null;
    dateSaisie=null;
    informations={};
    dateSaisie=new Date();
    commentaireMacif='';
    commentaireGestion='';
    acceptation=null;    
    autorisationFicheIncomplete=false;

    champsManquants = new Map([]);

    informations= {}

    constructor(idAssociation=null){        
        this.idAssociation = idAssociation;
        if(idAssociation === "PNCH"){
            this.noSoc = 9303995;
        }
    }


    get enregistrable(){
        if(this.autorisationFicheIncomplete) return true;
        return ( this.champsManquants.size === 0 && this.conditionsPourEnregistrable() );
    }

    conditionsPourEnregistrable(){
        return true;
    }

    messageSupplementaire(){
        return "";
    }

    setChampObligatoire(champ,etat=false,index=false){
        const idxChamp = index ? champ + index.toString() : champ;
        //console.log("setChampObligatoire",champ,etat)
        if(etat===false){
            if(!this.champsManquants.get(idxChamp)){
                this.champsManquants.set(idxChamp,true);
            }
        } else {
            if(this.champsManquants.get(idxChamp)){
                this.champsManquants.delete(idxChamp);
            }            
        }
    }

    set(champ, valeur,index=null) {           
        if(index!==null){
            const champs = champ.split(".");

            this.informations[champs[0]][index][champs[1]] = valeur;
        } else {
            this.informations[champ] = valeur;
        }             
        this.setIntercalaire();
        this.setAcceptation();
    }    

    get(champ,index=null){
        if(index!==null){
            const champs = champ.split(".");
            if(!this.informations[champs[0]]) return null;
            return this.informations[champs[0]][index][champs[1]];
        } else {
            return this.informations[champ];
        }
    }

    addElement(champ,element){
        this.informations[champ].push(element);
    }

    removeElement(champ,index){
        this.informations[champ].splice(index,1);
    }

    setIntercalaire(){
        
    }

    setAcceptation(){

    }

    setCommentaireMacif(texte){
        this.commentaireMacif = texte;
    }

    setCommentaireGestion(texte){
        this.commentaireGestion = texte;
    }


    setAutorisationFicheIncomplete(valeur){
        this.autorisationFicheIncomplete = valeur;
    }


}



decorate(RisqueStore, {
    id: observable,
    idAssociation: observable,
    dateDebut: observable,
    dateFin: observable,
    commentaireGestion: observable,
    commentaireGestion:observable,
    intercalaire: observable,
    informations: observable,
    autorisationFicheIncomplete: observable,
    acceptation: observable,
    champsManquants: observable,
    enregistrable: computed,
    set: action.bound,
    setAutorisationFicheIncomplete: action.bound,
    setIntercalaire: action.bound,
    addElement:action.bound,
    removeElement:action.bound,
    setCommentaireMacif:action.bound,
    setCommentaireGestion:action.bound,
    setAcceptation: action.bound,
    setChampObligatoire: action.bound
    /*
    demandesClient: computed,
    setDemande: action.bound,
    */
});


export default RisqueStore;