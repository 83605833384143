import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import SelectBoutonStore from '../../componants/UIStore/SelectBoutonStore';
import DateStore from '../../componants/UIStore/DateStore';
import BouleanStore from '../../componants/UIStore/BouleanStore';

import { InfoContrat } from '../../componants/InfoContrat';
import { Adresse } from '../../componants/Adresse';
import { ClauseBail } from '../../componants/ClauseBail';

import { observer, inject } from 'mobx-react';

import AjoutLogement from '../../componants/AjoutLogement'
import Logements from '../../componants/Logements'
import Logement from '../../componants/Logement'


var ResidenceSociale = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;


        const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

        const choixTypeResident = [
            { label: "Locataire", value: "locataire" },
            { label: "Propriétaire", value: "proprietaire" },
            { label: "Copropriétaire", value: "coproprietaire" }
        ]

        //console.log('nbre logement', risqueStore.informations.logements.length)

        const majLogement = (donnees) => {
            risqueStore.majLogement(donnees)
            risqueStore.setDetailLogement(null)
            store.save(false)
        }

        const supprimerLogement = (logement) => {
            risqueStore.supprimerLogement(logement)
            risqueStore.setDetailLogement(null)
            store.save(false)
        }

        const ajouterLogement = (logement)=> {
            risqueStore.ajouterLogement(logement)
            store.save(false)
        }

        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>Résidence Sociale</h1>
                <InfoContrat />
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                            <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                        </div>
                        <Adresse />
                    </div>
                    <div style={styleBlock}>
                        <SelectBoutonStore champ="typeOccupant" multiple={true} choix={choixTypeResident} libelle="Qualité occupant" />
                        {store.risqueStore.get('typeOccupant') === 'locataire' &&
                            <ClauseBail />
                        }
                        <InputStore libelle="Superficie" champ="superficie" largeur="100px" unite="m²" />
                        <BouleanStore libelle="Dans bâtiment >2500m2" champ="batimentGrand" />
                        <BouleanStore libelle="Bâtiment classé/inventorié monument historique (tout ou partie)" champ="batimentClasse" />
                        <InputStore libelle="Valeur contenu" champ="valeurContenu" largeur="100px" unite="€" />
                    </div>

                </div>

                <Logements logements={risqueStore.informations.logements} idRes={risqueStore.id} majLogements={(nvLogements) => risqueStore.informations.logements = nvLogements} onSelect={(logement) => risqueStore.setDetailLogement(logement)} />

                <AjoutLogement logement={{ numero: null, etage: null, nbrePieces: null }} saveLogement={ajouterLogement } nbreEtages={10} listeNumeros={risqueStore.informations.logements.map(l=>l.numero)} />
                <Logement logement={risqueStore.detailLogement} majLogement={majLogement} annuler={() => risqueStore.setDetailLogement(null)} supprimer={supprimerLogement} />

            </div>
        );

    })
)


export default ResidenceSociale;
