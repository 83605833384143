import React from 'react';
//import { decorate, observable, action, computed, reaction, ObservableMap } from 'mobx';
import RisqueStore from '../../store/Risque';
import Peniche from './index';
import { decorate, computed, autorun } from 'mobx';

/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class PenicheStore extends RisqueStore {

    constructor(idAssociation = null) {
        super(idAssociation);
        this.type = "peniche";
        this.contrat = "N001";
        this.acceptation = true;
        this.composant = <Peniche />        
        this.intercalaire = 10;
        this.acceptation = null;
        this.informations = {       
            numero: 1,
            marque: "",
            nomPeniche: "",            
            adresse: "",
            cp: "",
            commune: "",
            typeOccupant: "proprietaire",
            valeurPeniche: "",
            valeurContenu: ""
        }
        this.messageAttenteAcceptation = "Transmettre la demande à restos_du_coeur@macif.fr";
        //this.contrat=null;
        //this.intercalaire=null;
    }

    get identification(){
        return "Péniche "+this.informations.numero;
    }


}


export default PenicheStore;
