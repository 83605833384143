import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';


import { observer, inject } from 'mobx-react';



export const Adresse = inject('store')(
    observer(class Adresse extends React.Component {

        constructor(props) {
            super(props);
            const {risqueStore} = this.props.store;
            this.state = {
                commune: risqueStore.get("cp") && risqueStore.get("commune") ? risqueStore.get('commune') + ' ('+risqueStore.get('cp')+')' : '',
                saisie: '',
                selected: risqueStore.get("cp") && risqueStore.get("commune")
            }
        }

        filtrerCommune(event) {
            this.props.store.rechercherCommunes(event.query.toUpperCase());
        }

        itemTemplate(commune) {
            //console.log("template",commune);
            return (
                <div className="p-clearfix">
                    {commune.commune + ' (' + commune.cp + ')'}
                </div>
            );
        }

        onChange(e) {
            console.log('onChange', e, this.state.selected);
            if (!e.value.cp) {
                if (!this.state.selected) {
                    //this.setState({commune: this.state.})                
                    this.setState({ commune: e.value, saisie: e.value })
                } else {
                    this.setState({ commune: this.state.saisie, selected: false });
                }

            } else {
                this.setState({ commune: e.value.commune + ' (' + e.value.cp + ')' })
            }
        }

        onSelect(e) {
            console.log('onSelect', e)
            if (e.value && e.value.cp) {
                this.props.store.risqueStore.set('cp', e.value.cp);
                this.props.store.risqueStore.set('commune', e.value.commune);
                this.setState({ selected: true });
            }

        }

        selectedItemTemplate(c) {
            if (c.cp) {
                console.log('selectedItemTemplate', c, c.commune + ' (' + c.cp + ')');
                return c.commune + ' (' + c.cp + ')';
            } else {
                return c;
            }
        }


        render() {
            const store = this.props.store;
            this.props.store.risqueStore.setChampObligatoire("commune", this.state.selected);
            
            const disabled = this.props.store.readOnly;

            return (
                <React.Fragment>
                    <InputStore libelle="Adresse" champ="adresse" largeur="400px" obligatoire={true}  />
                    <InputStore libelle="Complement d'adresse" champ="adresse2" largeur="400px" obligatoire={false} />
                    <div style={{ display: 'block', margin: '5px' }}>
                        <label for="commune" class="frm_primary_label">Commune
                            <span class="frm_required" style={{ color: this.state.selected ? 'black' : 'red' }}>*</span>
                        </label>
                        <div className="p-inputgroup">
                            { !disabled  &&
                                <AutoComplete id="commune" itemTemplate={this.itemTemplate.bind(this)} onSelect={this.onSelect.bind(this)} selectedItemTemplate={this.selectedItemTemplate.bind(this)} value={this.state.commune} suggestions={store.listeCommunes} completeMethod={(e) => this.filtrerCommune(e)} field="name"
                                    size={30} minLength={1} onChange={this.onChange.bind(this)} delay={300} style={{ width: "400px" }} onKeyPress={(e)=>console.log(e)} />                            
                            } 
                            { disabled && 
                                <InputText value={store.risqueStore.get('commune') + ' ('+store.risqueStore.get('cp')+')' } disabled='disabled' style={{ width: "400px" }} />
                            }
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    })
)