import React from 'react';
import { facturerOui, facturerNon, dateEnFrancais } from '../../../Constantes';


export class Manifestions extends React.Component {
    
    render() {
        if(!this.props.risques || this.props.risques.length===0) return null
        return (
            <React.Fragment>
                <tr>
                    <th className='nom-risque' colSpan="16">Assurer une manifestation</th>
                </tr>
                <tr>
                    <th>N° Assuré</th>
                    <th>N° Contrat</th>
                    <th>N° Intercalaire</th>
                    <th>ID Asso</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Adresse de la salle</th>
                    <th>Nom de la salle</th>
                    <th>Type d'événement</th>
                    <th>Extérieur sans&nbsp;local</th>
                    <th>Nombre participants</th>
                    <th>Superficie Salle</th>
                    <th>Située&nbsp;dans un&nbsp;bâtiment >2500m²</th>
                    <th>bâtiment classé/inventorié monument&nbsp;historque, en&nbsp;tout&nbsp;ou&nbsp;partie</th>
                    <th></th>
                    <th className='facturation'></th>
                </tr>
                {this.props.risques.map((risque, i) => <tr key={i}>
                    <td>{risque.noSoc}</td>
                    <td>{risque.contrat}</td>
                    <td>{risque.intercalaire}</td>
                    <td>{risque.idAssociation}</td>
                    <td>{ dateEnFrancais(risque.dateDebut) }</td>
                    <td>{ this.props.mois === risque.moisSortieFacturation && dateEnFrancais(risque.dateFin)}</td>
                    <td>{risque.adresse}</td>
                    <td>{risque.nomSalle}</td>
                    <td>{risque.typeEvenement}</td>
                    <td>{risque.exterieur}</td>
                    <td>{risque.nombreParticipants}</td>
                    <td>{risque.superficie}</td>
                    <td>{risque.batimentGrand}</td>
                    <td>{risque.batimentClasse}</td>
                    <td></td>
                    <td className='facturation'>{risque.facturer ? facturerOui : facturerNon}</td>
                </tr>)}
            </React.Fragment>
        )
    }


}