import React from 'react';
import { facturerOui, facturerNon, dateEnFrancais } from '../../../Constantes';


export class ImmosVentes extends React.Component {


    render() {
        if (!this.props.risques || this.props.risques.length === 0) return null
        return (
            <React.Fragment>
                <tr>
                    <th className='nom-risque' colSpan="16">Assurer un bien immobilier en attente de vente</th>
                </tr>
                <tr>
                    <th>N° Assuré</th>
                    <th>N° Contrat</th>
                    <th>N° Intercalaire</th>
                    <th>ID Asso</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Adresse</th>
                    <th>Qualité d'occupant</th>
                    <th>Surface</th>
                    <th colSpan='6'></th>
                    <th className='facturation'></th>
                </tr>
                {this.props.risques.map((risque, i) => <tr key={i}>
                    <td>{risque.noSoc}</td>
                    <td>{risque.contrat}</td>
                    <td>{risque.intercalaire}</td>
                    <td>{risque.idAssociation}</td>
                    <td>{dateEnFrancais(risque.dateDebut)}</td>
                    <td>{ this.props.mois === risque.moisSortieFacturation && dateEnFrancais(risque.dateFin)}</td>
                    <td>{risque.adresse}</td>
                    <td>{risque.typeOccupant}</td>
                    <td>{risque.superficie}</td>
                    <td colSpan="6"></td>
                    <td className='facturation'>{risque.facturer ? facturerOui : facturerNon}</td>
                </tr>)}
            </React.Fragment>
        )
    }


}