import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { observer, inject } from 'mobx-react';

const BouleanStore = inject("store")(
    observer(class InputStore extends React.Component {

        render() {
            const risqueStore = this.props.store.risqueStore;

            const { champ, vrai, faux, libelle, index, obligatoire } = this.props;

            const choix = [
                { label: vrai ? vrai : 'Oui', value: true },
                { label: faux ? faux : 'Non', value: false }
            ]

            const valeur = risqueStore.get(champ, index);

            if (obligatoire) {
                risqueStore.setChampObligatoire(champ, valeur !== null, index);
            }

            const disabled = this.props.store.readOnly;

            return (
                <div style={{ display: 'block', margin: '5px' }}>
                    {libelle > '' &&
                        <label for={champ} class="frm_primary_label">{libelle}
                            {obligatoire && <span class="frm_required" style={{ color: valeur ? 'black' : 'red' }}> *</span>}
                        </label>
                    }
                    <SelectButton value={valeur} options={choix} onChange={(e) => risqueStore.set(champ, e.value, index)} disabled={disabled} />
                </div>
            );
        }

    })
)

export default BouleanStore;


