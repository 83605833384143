import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import BouleanStore from '../../componants/UIStore/BouleanStore';
import DateStore from '../../componants/UIStore/DateStore';
import SelectStore from '../../componants/UIStore/SelectStore';
import { InfoContrat } from '../../componants/InfoContrat';
import { Adresse } from '../../componants/Adresse';
import { observer, inject } from 'mobx-react';

var ImmoVente = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;

        const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

        const listeTypeBien = [
            { label: 'Parking', value: 'parking' },
            { label: 'Terrain', value: 'terrain' },
            { label: 'Appartement', value: 'appartement' },
            { label: 'Maison', value: 'maison' },
            { label: 'Garage', value: 'garage' },
            { label: 'Château', value: 'chateau' },
            { label: 'Autre', value: 'autre' },
        ]


        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>Bien immobilier en attente de vente</h1>
                <InfoContrat />
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                            <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                        </div>
                        <Adresse />
                    </div>
                    <div style={styleBlock}>
                        <SelectStore champ="typeDeBien" multiple={false} choix={listeTypeBien} libelle="Type de bien" obligatoire={true} largeur="300px" />
                        {risqueStore.get("typeDeBien") === "autre" &&
                            <InputStore champ="descriptifDuBien" libelle="Descriptif du bien" obligatoire={true} largeur="300px" />
                        }
                        <InputStore libelle="Superficie" champ="superficie" unite="m²" largeur="100px" />
                        <BouleanStore libelle="Dans bâtiment >2500m2" champ="batimentGrand" />
                        <BouleanStore libelle="Bâtiment classé/inventorié monument historique (tout ou partie)" champ="batimentClasse" />
                    </div>

                </div>
            </div>
        );

    })

    
)


export default ImmoVente;
