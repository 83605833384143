import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
//import BouleanStore from '../../componants/UIStore/BouleanStore';
import { Button } from 'primereact/button';
import DateStore from '../../componants/UIStore/DateStore';
import SelectStore from '../../componants/UIStore/SelectStore';
import { api } from '../../api';

import { InfoContrat } from '../../componants/InfoContrat';
//import { Activites, ActivitesPeniche } from '../../Constantes';
import { observer, inject } from 'mobx-react';

var Activite = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;

        const listeActivites = risqueStore.listeActivites.sort((a, b) => (a.label > b.label) ? 1 : -1) //.map(a => { return { label: a.libelle, value: a.libelle } });

        //const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }
        // extérieur , batimentClasse:null,

        const participantsArray = risqueStore.get("occupants") || [];

        //console.log(participantsArray)

        const participants = participantsArray.map((participant, i) => {
            const readOnly = store.user.role === 'lecteur';
            return {
                index: i,
                componant:
                    <div key={i} style={styleFlex}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <InputStore libelle="Nom" champ="occupants.nom" index={i} largeur="200px" obligatoire={true} readOnly={readOnly} />
                            <InputStore libelle="Prénom" champ="occupants.prenom" index={i} largeur="200px" obligatoire={true} readOnly={readOnly} />
                            {!readOnly &&
                                <span className="petitBouton" style={{ height: '20px', marginTop: '2.2rem' }} onClick={() => risqueStore.removeElement('occupants', i)} >Supprimer</span>
                            }
                            &nbsp; &nbsp; <a className="petitBouton" href={api.getPdfUrl("ATT23/" + risqueStore.id + '|' + i)} style={{ marginTop: '2.2rem' }} target="_blank">Att. assistance</a>
                            &nbsp; &nbsp; <a className="petitBouton" href={api.getPdfUrl("ATT25/" + risqueStore.id + '|' + i)} style={{ marginTop: '2.2rem' }} target="_blank">Att RC vie privée</a>
                        </div>
                    </div>
            }
        })

        const participantsHtml = participants.map(o => o.componant)

        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>Activité</h1>
                <InfoContrat />
                <div style={styleFlex}>
                    <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                    <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                </div>
                <div style={styleFlex}>
                    <SelectStore champ="natureActivite" multiple={false} choix={listeActivites} libelle="Nature de l'activité" obligatoire={true} largeur="300px" />
                </div>
                {risqueStore.get("natureActivite") === "sport" &&
                    <div style={styleFlex}>
                        <InputStore champ="sport" libelle="Sport pratiqué" obligatoire={true} largeur="300px" />
                    </div>
                }
                {risqueStore.get("natureActivite") === "autre" &&
                    <div style={styleFlex}>
                        <InputStore champ="detailActivite" libelle="Descriptif" obligatoire={true} largeur="300px" />
                    </div>
                }
                {risqueStore.get("natureActivite") === "vacances" &&
                    <div style={styleFlex} >
                        <InputStore champ="detailActivite" libelle="Lieu" obligatoire={true} largeur="300px" />
                    </div>
                }

                {risqueStore.get("natureActivite") === "vacances" && store.user.role !== 'lecteur' &&
                    <div style={styleFlex}>
                        <Button label="Ajouter un participant" icon="pi pi-add" onClick={() => store.risqueStore.addElement('occupants', { prenom: '', nom: '' }, 'fin')} />
                    </div>
                }

                {participants.length > 0 &&
                    <React.Fragment>
                        <div style={styleFlex}>
                            <h3 style={{ marginTop: '2rem' }}>{participants.length} Participant{participants.length > 1 && 's'}</h3>
                        </div>

                        {participantsHtml}

                    </React.Fragment>
                }

                {store.readOnly && store.user.role !== 'lecteur' &&
                    <div style={styleFlex}>
                        <Button label="Enregistrer les participants" icon="pi pi-add" onClick={() => store.saveOccupants()} style={{ marginTop: '10px' }} />
                    </div>
                }
            </div>
        );

    })
)


export default Activite;
