import React from "react";
//import { decorate, observable, action, computed, reaction, ObservableMap } from 'mobx';
import RisqueStore from "../../store/Risque";
import LocalExploitation from "./index";
import { decorate, computed, autorun } from "mobx";

/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class LocalExploitationStore extends RisqueStore {
    constructor(idAssociation = null) {
        super(idAssociation);
        this.type = "localExploitation";
        this.contrat = "T001";
        this.acceptation = true;
        this.composant = <LocalExploitation />;
        this.informations = {
            dateDebut: null,
            dateFin: null,
            adresse: "",
            cp: "",
            commune: "",
            typeOccupant: "proprietaire",
            typeLocal: "",
            usageBungalow: null,
            typeLocalAutre: null,
            superficie: "",
            valeurContenu: "",
            chambreFroide: null,
            nbreChambreFroide: "",
            batimentGrand: null,
            batimentClasse: null,
            occupOccasionelle: false,
            detailOccupation: "",
        };
        this.messageAttenteAcceptation = "Transmettre le bail ou la convention d’occupation à restos_du_coeur@macif.fr";
        //this.contrat=null;
        //this.intercalaire=null;
    }

    setIntercalaire() {
        const { typeLocal, valeurContenu } = this.informations;
        this.intercalaire =  ( typeLocal === "bungalow" || typeLocal === "autre") ? 31 :
                typeLocal === "siege" ? 30 : 
                    typeLocal === "ateliersJardin" ? 
                        ( Number(valeurContenu) <= 50000 ? 30 : 31 ) :
                        ( Number(valeurContenu) <= 24000 ? 30 : 31) ;
    }

    setAcceptation() {
        const { superficie, batimentClasse, batimentGrand, valeurContenu, typeLocal } = this.informations;
        this.acceptation = Number(superficie) > 2500 || batimentGrand || batimentClasse || valeurContenu > 2337825 || typeLocal === "autre" ? null : true;
    }
}

export default LocalExploitationStore;
