class Api {

    constructor() {
        console.log("%REACT_APP_DESTINATAIRE%");
        console.log('env',process.env.REACT_APP_DESTINATAIRE);
        if (process.env.NODE_ENV === "development") {            
            this.urlApi = "http://127.0.0.1:3000/api/";
            //this.urlApi = "https://api.macif.fr/resto-du-coeur/v1/api/";
            //this.urlApi = "https://apigw-vip.recette.macif.fr/api/";
            //https://apigw-vip.recette.macif.fr/api/";
        } else {
            //this.urlApi = "/api/";   
            //this.urlApi = "https://api.macif.fr/resto-du-coeur/v1.1/api/";                     
            this.urlApi = ( (process.env.REACT_APP_DESTINATAIRE && process.env.REACT_APP_DESTINATAIRE ==="MACIF") || (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "DEV") ) ? "/api/" : "https://api.macif.fr/resto-du-coeur/v1.1/api/";
        }

        this.jwt = '';
        
        //this.urlApi = "/api/"
    }

    apiFetch(url) {
        return fetch(url,{ headers: {"Cache-Control":"no-cache, no-transform", "Authorization": `JWT ${this.jwt}`} } );
    }    

    getUser(){
        return fetch(this.urlApi + "infoUser", {headers: { Authorization: `JWT ${this.jwt}` }} ).then( res => res.json());       
    }

    save(risque) {
        const objEval = JSON.stringify(risque);
        console.log("Save",risque);
        return fetch(this.urlApi + "risques/save", {
            method: 'POST',
            body: objEval, // stringify JSON
            headers: new Headers({ "Content-Type": "application/json", "Authorization": `JWT ${this.jwt}` }) // add headers    
        }).then( (res) => res.json());
    }

    delete(id) {
        return fetch(this.urlApi + "risques/"+ id, {
            method: 'DELETE',            
            headers: new Headers({ "Content-Type": "application/json" , "Authorization": `JWT ${this.jwt}`}) // add headers    
        });        
    }

    resilier(id,dateFin) {
        return fetch(this.urlApi + "risques/resilier/"+ id, {
            method: 'POST',           
            body: JSON.stringify({dateFin:dateFin}),
            headers: new Headers({ "Content-Type": "application/json", "Authorization": `JWT ${this.jwt}` }) // add headers    
        });        
    }


    getRisque(id) {
        return this.apiFetch(this.urlApi + "risques/lire/" + id).then((res) => {
            //console.log("reponse",res,typeof(res));
            //res.json()
            return res.text()
        });
    }    

    getRisques(idAssociation, corbeilleResto){
        const url = (idAssociation === false) ? "" : "/parAssociation/" + idAssociation;
        const corbeilleParam  = corbeilleResto ? '?corbeilleResto=1' : ''
        return this.apiFetch(this.urlApi + "risques" + url + corbeilleParam ).then((res) => res.json());        
    }

    getPeniches(){        
        return this.apiFetch(this.urlApi + "risques/peniches"  ).then((res) => res.json());        
    }


    getCorbeille(){        
        return this.apiFetch(this.urlApi + "risques/corbeille/aTraiter" ).then((res) => res.json());        
    }
    
    rechercherCommunes(recherche){
       // return this.apiFetch(this.urlApi + 'rechercheCommune/' + recherche).then( res => res.json());
       return this.apiFetch(this.urlApi + 'risques/lire/commune,' + recherche).then( res => res.json());
    }

    

    getPdfUrl(url){
        return this.urlApi + "risques/pdf/"+url+"?JWT="+this.jwt;        
    }

    login(user,password){
        /*
        let formData = new FormData();
        formData.append('username', user);
        formData.append('password', password);       
        */

        return fetch(this.urlApi + "auth/login", {
            method: 'POST',           
            body: 'username='+user+'&password='+password, //formData,
            //credentials: "include",
            //credentials: 'same-origin',
            headers: new Headers({ "Content-Type": "application/x-www-form-urlencoded" }) // add headers    
        }).then( res => res.json());
    }

    changePswd(oldPswd,newPswd){

        return fetch(this.urlApi + "auth/changePswd", {
            method: 'POST',           
            body: 'oldPswd='+oldPswd+'&newPswd='+newPswd, //formData,
            //credentials: "include",
            //credentials: 'same-origin',
            headers: new Headers({ "Content-Type": "application/x-www-form-urlencoded" }) // add headers    
        }).then( res => res.json());        
    }


    getMoisFacturation(){
        return this.apiFetch(this.urlApi + "getMoisFacturation" ).then((res) => res.json());        
    }

    getRestitution(moisFacturation){
        return this.apiFetch(this.urlApi + "restitution" + ( moisFacturation ? '/'+moisFacturation : '' ) ).then((res) => res.json());        
    }

    figerRestitution(){
        return this.apiFetch(this.urlApi + "figerRestitution"  ).then((res) => res.json());        
    }
}

export const api = new Api();
