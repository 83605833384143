import React from 'react';
import { decorate, action, observable } from 'mobx';
import RisqueStore from '../../store/Risque';
import ResidenceSociale from './index';
/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class ResidenceSocialeStore extends RisqueStore {

    constructor(idAssociation){
        super(idAssociation);        
        this.contrat = "L001";
        this.intercalaire = "10";
        this.type = "residenceSociale";
        this.composant = <ResidenceSociale/>
        this.acceptation = null;
        this.informations = {  
            dateDebut: null,
            dateFin: null,                                  
            adresse: "",
            cp:"",
            commune:"",
            typeOccupant:null,            
            nombreLogements:"",
            superficie:"",
            valeurContenu:"",
            batimentGrand:null,
            batimentClasse:null,
            logements:[],
            nbreEtages:0                      
        }
        this.messageAttenteAcceptation = "Transmettre la convention de mise à disposition (ou bail) par mail à à restos_du_coeur@macif.fr";
        this.detailLogement = null
    }

    ajouterLogement(logement){
        this.informations.logements.push({...logement,occupants:[]})
    }

    majLogement(donnees){
        const logement = this.informations.logements.find(l=>l.numero === donnees.numero)
        if(logement){
            logement.dateDebut = donnees.dateDebut
            logement.occupants = donnees.occupants
        }
    }

    setDetailLogement(logement){
        console.log('setDetail',logement)
        this.detailLogement = logement
    }

    supprimerLogement(logement){
        this.informations.logements = this.informations.logements.filter(l=>l.numero !== logement.numero)
    }
/*
    setAcceptation() {
        
        const {superficie,batimentClasse,batimentGrand,typeDeBien} = this.informations;
        if(Number(superficie)>2500 || batimentGrand || batimentClasse ){
            this.acceptation = null;
        } else {
            this.acceptation = true;
        }
    }        
*/
}

decorate(ResidenceSocialeStore, {
    ajouterLogement:action.bound,
    setDetailLogement:action.bound,
    majLogement:action.bound,
    detailLogement: observable
})

export default ResidenceSocialeStore;