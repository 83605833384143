class Api {

    constructor() {
        
        if (process.env.NODE_ENV === "development") {            
            this.urlApi = "http://127.0.0.1:3000/api/admin/";
            //this.urlApi = "https://api.macif.fr/resto-du-coeur/v1/api/";
            //this.urlApi = "https://apigw-vip.recette.macif.fr/api/";
            //https://apigw-vip.recette.macif.fr/api/";
        } else {
            this.urlApi = "/api/admin/";
            //this.urlApi = "https://api.macif.fr/resto-du-coeur/v1/api/";
        }
        
        //this.urlApi = "/api/"
    }

    apiFetch(url) {
        return fetch(url,{ headers: {"Cache-Control":"no-cache, no-transform"} } );
    }    

    getUsers(){
        return fetch(this.urlApi + "users" ).then( res => res.json());       
    }

    register(username,nom,prenom,idAsso,password){
        return fetch(this.urlApi + "register", {
            method: 'POST',
            body: JSON.stringify({username:username,nom:nom,prenom:prenom,idAsso:idAsso,password:password}),
            headers: new Headers({ "Content-Type": "application/json" })
            //credentials: "include",
            //credentials: 'same-origin',            
        });        
    }

    deleteUser(userId){
        return fetch(this.urlApi + "users/"+ userId, {
            method: 'DELETE',            
            headers: new Headers({ "Content-Type": "application/json" }) // add headers    
        }); 
    }

}

export const api = new Api();