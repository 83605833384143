import { RouterState, RouterStore } from 'mobx-state-router';
import { decorate, observable, action, computed, runInAction } from 'mobx';
import { routes } from './routes';

import ActiviteStore from '../risques/Activite/store';
import ManifestationStore from '../risques/Manifestation/store';
import ResidenceSocialeStore from '../risques/ResidenceSociale/store';
import ImmoVenteStore from '../risques/ImmoVente/store';
import AppartementStore from '../risques/Appartement/store';
import PersonneResSocStore from '../risques/PersonneResSoc/store';
import LocalExploitationStore from '../risques/LocalExploitation/store';
import PenicheStore from '../risques/Peniche/store';

import AdminStore from './Admin';

import React from 'react';

import { Activites } from '../Constantes';

import { api } from '../api';
//import { threadId } from 'worker_threads';


const notFound = new RouterState('notFound');

const libellesAttestations = new Map([
    ["ATT01", "RC Générale"],
    ["ATT02", "RC Générale"],
    ["ATT03", "Dommages Corp"],
    ["ATT04", "Dommages Corp"],
    ["ATT05", "RC Activités"],
    ["ATT06", "RC Occupant occasionnel"],
    ["ATT07", "RC Occupant occasionnel"],
    ["ATT08", "RC Occupant"],
    ["ATT09", "RC Occupant"],
    ["ATT10", "RC manif. sport"],
    ["ATT11", "RC manif. sport"],
    ["ATT12", "RC civile locative"],
    ["ATT15", "RC Navigation de plaisance"],
    ["ATT16", "RC générale"],
    ["ATT18", "RC Autor. préfect."],
    ["ATT19", "RC Autor. préfect."],
    ["ATT20", "RC occupant occasionnel"],
    ["ATT21", "RC occupant occasionnel"],
    ["ATT22", "RC Villégiature"],


])

class RootStore {


    activites = Activites;

    routerStore = new RouterStore(this, routes, notFound);

    risqueStore = null;

    adminStore = new AdminStore(this);

    filtreTypes = null;

    user = null;

    listePeniche = [];

    restitution = null;

    moisFacturation = [];

    choixMoisFacturation = null;

    choixRisque = null;
    setChoixRisque(risque) {
        this.choixRisque = risque;
    }

    goTo(route) {
        this.ancienneRoute = this.routerStore.routerState;
        this.routerStore.goTo(route);
    }

    retour() {
        this.goTo(this.ancienneRoute);
    }

    get menu() {
        let menu = [];
        const visible = this.assoChoisie !== null || (this.user && this.user.type === "MACIF");
        if (this.user && this.user.type === "RESTO" && this.user.idAsso === 'NAT' || this.user && this.user.type === "MACIF") {
            menu.push({ label: 'Connexion', value: 'choixNatDep', actif: true, visible: this.assoChoisie !== null && this.user.idAsso === "NAT" });
        }
        if (this.user && this.user.type === "RESTO") {
            menu.push({ label: 'Assurer', value: 'assurer', actif: false, visible: visible });
            menu.push({ label: 'Liste des risques', value: 'attestation', actif: false, visible: visible });
        } else {
            menu.push({ label: 'Liste des risques', value: 'risques', actif: false, visible: visible });
            if (this.user && this.user.role !== "lecteur") {
                menu.push({ label: 'Corbeille Gest', value: 'corbeille', actif: false, visible: visible });
            }
            if (this.user && this.user.role === "admin") {
                menu.push({ label: 'Administration', value: 'admin', actif: false, visible: visible });
            }
        }
        if (this.user && this.user.type === "RESTO") {
            menu.push({ label: 'A reprendre', value: 'corbeilleResto', actif: false, visible: visible });
        } else {
            menu.push({ label: 'Restitution', value: 'restitution', actif: false, visible: visible });
        }
        menu.push({ label: 'Aide', value: 'aide', actif: false, visible: visible });

        return menu.map(m => {
            m.actif = this.routerStore.routerState.routeName === m.value;
            return m;
        });
    }

    listeRisque = [];

    listeCommunes = [];


    login(user, password) {
        //console.log("login",user,password);
        api.login(user, password).then(rep => {
            console.log(rep.token);
            localStorage.setItem('jwt', rep.token);
            this.jwt = rep.token;
            api.jwt = rep.token;
            this.getUser()
        });

    }


    get listeChoixRisques() {

        const choix = [];

        if (this.assoChoisie === 'NAT') {
            choix.push({
                label: 'Une association Départementale',
                value: null,
                bouton: <button className="bouton-choix bouton-risque" onClick={() => console.log("rien")} disabled="disabled" >
                    <div class="icon-espace-sante icon-risque"></div>
                    <div class="texte-risque">Une association<br />Départementale</div>
                </button>
            })
        }

        if (this.assoChoisie === "PNCH") {
            choix.push({
                label: 'Une péniche',
                value: 'peniche',
                bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("peniche")}>
                    <div class="icon-nvl icon-risque"></div>
                    <div class="texte-risque">Une péniche</div>
                </button>
            })

            choix.push({
                label: 'Des personnes hébergées',
                value: 'personnes',
                bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("personnes")}>
                    <div class="icon-colocation icon-risque"></div>
                    <div class="texte-risque">Des personnes hébergées</div>
                </button>
            })
        }


        choix.push({
            label: 'Une activité',
            value: 'activite',
            bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("activite")} >
                <div class="icon-adaptabilite icon-risque"></div>
                <div class="texte-risque" >Une activité</div>
            </button>
        })

        choix.push({
            label: 'Une manifestation',
            value: 'manifestation',
            bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("manifestation")} >
                <div class="icon-adaptabilite icon-risque"></div>
                <div class="texte-risque" >Une manifestation</div>
            </button>
        })


        if (this.assoChoisie !== "PNCH") {
            choix.push({
                label: 'Une résidence sociale, un hôtel social',
                value: 'residenceSociale',
                bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("residenceSociale")}>
                    <div class="icon-produit-option-ba icon-risque"></div>
                    <div class="texte-risque">Une résidence sociale, un hôtel social</div>
                </button>
            })
            choix.push({
                label: 'Un bien immobilier  en attente de vente',
                value: 'immoVente',
                bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("immoVente")}>
                    <div class="icon-gem icon-risque"></div>
                    <div class="texte-risque">Un bien immobilier  en attente de vente</div>
                </button>
            })
            choix.push({
                label: 'Un appartement',
                value: 'appartement',
                bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("appartement")}>
                    <div class="icon-macif-mobile-maison icon-risque"></div>
                    <div class="texte-risque">Un appartement</div>
                </button>
            })
            choix.push({
                label: "Un local d'exploitation",
                value: 'localExploitation',
                bouton: <button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("localExploitation")}>
                    <div class="icon-produit-option-dp icon-risque"></div>
                    <div class="texte-risque">Un local d'exploitation</div>
                </button>
            })
            choix.push({
                label: "Des occupants d'une résidence sociale, d'un hôtel social",
                value: 'personneResSoc',
                bouton: < button className="bouton-choix bouton-risque" onClick={() => store.creerRisque("personneResSoc")}>
                    <div class="icon-colocation icon-risque"></div>
                    <div class="texte-risque">Des occupants d'une résidence sociale, d'un hôtel social</div>
                </button >
            })
        }




        return choix;
    }

    get listeCommunesLibelle() {
        return this.listeCommunes.map(c => c.commune + ' (' + c.cp + ')');
    }

    rechercherCommunes(recherche) {
        api.rechercherCommunes(recherche).then(liste => runInAction(() => this.listeCommunes = liste));
    }

    assoChoisie = null;

    get readOnly() {
        //Pendant la période de saisie uniquement

        if (this.user && this.user.role && this.user.role === 'lecteur') {
            return true;
        }

        if (this.routerStore.routerState.routeName === "majGestionnaireRisque") {
            return true;
        }

        if (this.user && this.user.type === "MACIF") {
            return false;
        }

        if (this.risqueStore && this.risqueStore.id) {
            return true;
        }

        return false;

    }

    get titreAssociation() {
        if (this.assoChoisie) {
            if (this.assoChoisie === "PNCH") return "Péniche du coeur";
            if (this.assoChoisie === "NAT") return "Association nationale";
            return this.associations.find(a => a.id === this.assoChoisie).nom;
        } else {
            return false;
        }
    }

    associations = [
        { id: "AD01", nom: "AIN" },
        { id: "AD02", nom: "AISNE" },
        { id: "AD02I", nom: "AISNE - Insertion" },
        { id: "AD03", nom: "ALLIER" },
        { id: "AD04", nom: "ALPES DE HAUTE PROVENCE" },
        { id: "AD05", nom: "HAUTES ALPES" },
        { id: "AD06", nom: "ALPES MARITIMES" },
        { id: "AD07", nom: "ARDECHE" },
        { id: "AD08", nom: "ARDENNES" },
        { id: "AD09", nom: "ARIEGE" },
        { id: "AD10", nom: "AUBE" },
        { id: "AD11", nom: "AUDE" },
        { id: "AD12", nom: "AVEYRON" },
        { id: "AD13", nom: "BOUCHES DU RHONE" },
        { id: "AD13I", nom: "BOUCHES DU RHONE - Insertion" },
        { id: "AD14", nom: "CALVADOS" },
        { id: "AD15", nom: "CANTAL" },
        { id: "AD16", nom: "CHARENTE" },
        { id: "AD17", nom: "CHARENTE MARITIME" },
        { id: "AD18", nom: "CHER" },
        { id: "AD19", nom: "CORREZE" },
        { id: "AD20A", nom: "CORSE - Ajaccio" },
        { id: "AD20B", nom: "CORSE - Bastia" },
        { id: "AD21", nom: "COTE D’OR" },
        { id: "AD21T", nom: "COTE D’OR - Toit du Cœur" },
        { id: "AD22", nom: "COTES D'ARMOR" },
        { id: "AD22I", nom: "COTES D'ARMOR - Insertion" },
        { id: "AD23", nom: "CREUSE" },
        { id: "AD24", nom: "DORDOGNE" },
        { id: "AD25", nom: "DOUBS" },
        { id: "AD26", nom: "DROME" },
        { id: "AD26I", nom: "DROME - Insertion" },
        { id: "AD27", nom: "EURE" },
        { id: "AD28", nom: "EURE ET LOIR" },
        { id: "AD28R", nom: "EURE ET LOIR - Petite Ruche" },
        { id: "AD29", nom: "FINISTERE" },
        { id: "AD30", nom: "GARD" },
        { id: "AD31", nom: "HAUTE GARONNE" },
        { id: "AD32", nom: "GERS" },
        { id: "AD33", nom: "GIRONDE" },
        { id: "AD34", nom: "HERAULT" },
        { id: "AD35", nom: "ILLE ET VILAINE" },
        { id: "AD36", nom: "INDRE" },
        { id: "AD37", nom: "INDRE ET LOIRE" },
        { id: "AD38", nom: "ISERE" },
        { id: "AD38VA", nom: "VAL D'AKOR" },
        { id: "AD39", nom: "JURA" },
        { id: "AD40", nom: "LANDES" },
        { id: "AD41", nom: "LOIR ET CHER" },
        { id: "AD41R", nom: "LOIR ET CHER - Petite Ruche" },
        { id: "AD42", nom: "LOIRE" },
        { id: "AD43", nom: "HAUTE LOIRE" },
        { id: "AD44", nom: "LOIRE ATLANTIQUE" },
        { id: "AD45", nom: "LOIRET" },
        { id: "AD46", nom: "LOT" },
        { id: "AD47", nom: "LOT ET GARONNE" },
        { id: "AD48", nom: "LOZERE" },
        { id: "AD49", nom: "MAINE ET LOIRE" },
        { id: "AD50", nom: "MANCHE" },
        { id: "AD51", nom: "MARNE" },
        { id: "AD52", nom: "HAUTE MARNE" },
        { id: "AD53", nom: "MAYENNE" },
        { id: "AD54", nom: "MEURTHE ET MOSELLE" },
        { id: "AD55", nom: "MEUSE" },
        { id: "AD56", nom: "MORBIHAN" },
        { id: "AD57A", nom: "MOSELLE Ouest - Metz" },
        { id: "AD57C", nom: "MOSELLE Est  - Forbach" },
        { id: "AD58", nom: "NIEVRE" },
        { id: "AD59A", nom: "NORD - Lille" },
        { id: "AD59B", nom: "NORD - Valenciennes" },
        { id: "AD59C", nom: "NORD - Dunkerque" },
        { id: "AD59D", nom: "NORD  - Maubeuge" },
        { id: "AD60", nom: "OISE" },
        { id: "AD61", nom: "ORNE" },
        { id: "AD62A", nom: "PAS DE CALAIS  - Lens" },
        { id: "AD62B", nom: "PAS DE CALAIS - Arras" },
        { id: "AD62BI", nom: "PAS DE CALAIS - Insertion" },
        { id: "AD62C", nom: "PAS DE CALAIS - Côte d’Opale" },
        { id: "AD63", nom: "PUY DE DOME" },
        { id: "AD64", nom: "PYRENEES ATLANTIQUES" },
        { id: "AD65", nom: "HAUTES PYRENEES" },
        { id: "AD66", nom: "PYRENEES ORIENTALES" },
        { id: "AD67", nom: "BAS RHIN" },
        { id: "AD68", nom: "HAUT RHIN" },
        { id: "AD69", nom: "RHONE" },
        { id: "AD70", nom: "HAUTE SAONE" },
        { id: "AD71", nom: "SAONE ET LOIRE" },
        { id: "AD72", nom: "SARTHE" },
        { id: "AD73", nom: "SAVOIE" },
        { id: "AD74", nom: "HAUTE SAVOIE" },
        { id: "AD74MC", nom: "HAUTE SAVOIE - MC" },
        { id: "AD75", nom: "PARIS" },
        { id: "AD75LP", nom: "PARIS - La Peniche du Coeur" },
        { id: "AD75LT", nom: "PARIS - Les Tremplins des RdC – Relais du coeur" },
        { id: "AD76A", nom: "SEINE MARITIME - Rouen" },
        { id: "AD76L", nom: "SEINE MARITIME - Le Havre + Dieppe" },
        { id: "AD77", nom: "SEINE ET MARNE" },
        { id: "AD78", nom: "YVELINES" },
        { id: "AD79", nom: "DEUX SEVRES" },
        { id: "AD80", nom: "SOMME" },
        { id: "AD81", nom: "TARN" },
        { id: "AD82", nom: "TARN ET GARONNE" },
        { id: "AD83", nom: "VAR" },
        { id: "AD84", nom: "VAUCLUSE" },
        { id: "AD85", nom: "VENDEE" },
        { id: "AD86", nom: "VIENNE" },
        { id: "AD87", nom: "HAUTE VIENNE" },
        { id: "AD88", nom: "VOSGES" },
        { id: "AD89", nom: "YONNE" },
        { id: "AD90", nom: "TERRITOIRE DE BELFORT" },
        { id: "AD91", nom: "ESSONNE" },
        { id: "AD92", nom: "HAUTS DE SEINE" },
        { id: "AD93", nom: "SEINE SAINT DENIS" },
        { id: "AD94", nom: "VAL-DE-MARNE" },
        { id: "AD94A2EF", nom: "Ass. de l’Entrepôt Est-Francilien des RdC" },
        { id: "AD95", nom: "VAL D'OISE" }
    ]

    choixAssoAttestation = null;

    setChoixAssoAttestation(asso) {
        console.log('choix', asso);
        this.choixAssoAttestation = asso;
    }


    getUser() {
        api.getUser().then((user) => runInAction(() => {
            this.user = user;
            //console.log("user",user)
            if (!this.user.type) {
                this.goTo('login');
            } else {
                this.lirePeniches();
                if (this.user.type === "RESTO" && user.idAsso !== "NAT") {
                    //console.log("assurer");
                    this.setChoixAsso(user.idAsso);
                    this.goTo('assurer');
                } else if (user.type === "MACIF") {
                    //console.log("corbeille");
                    if (user.role === "lecteur") {
                        this.goTo('risques');
                    } else {
                        this.goTo('corbeille');
                    }

                    //this.setChoixAsso('NAT');
                } else {
                    //console.log("choixNatDep");  
                    this.goTo('choixNatDep');

                }
            }
        }))
    }



    setChoixAsso(value) {
        this.assoChoisie = value;
        //this.menu.forEach(i => i.visible = true);
        if (this.user && this.user.type === 'RESTO') this.goTo("assurer");
    }

    accepterRisque() {
        this.risqueStore.acceptation = true;
        this.save("corbeille");
    }

    refuserRisque() {
        this.risqueStore.acceptation = false;
        this.save("corbeille");
    }


    save(fermer = true) {
        if (this.risqueStore) {
            if (!this.risqueStore.informations.dateFin && this.risqueStore.type === 'manifestation') {
                this.risqueStore.informations.dateFin = new Date(this.risqueStore.informations.dateDebut.getTime() + (60 * 60 * 24 * 1000));
            }
            api.save({
                id: this.risqueStore.id,
                noSoc: this.risqueStore.noSoc,
                informations: this.risqueStore.informations,
                idAssociation: this.risqueStore.idAssociation,
                type: this.risqueStore.type,
                acceptation: (this.user && this.user.type === 'MACIF' && this.risqueStore.acceptation !== false) ? true : this.risqueStore.acceptation,
                contrat: this.risqueStore.contrat,
                intercalaire: this.risqueStore.intercalaire,
                dateSaisie: this.risqueStore.dateSaisie,
                commentaireMacif: this.risqueStore.commentaireMacif,
                commentaireGestion: this.risqueStore.commentaireGestion
            }).then(risque => {
                this.risqueStore.id = risque._id;
                if (this.risqueStore.type === "peniche") {
                    this.lirePeniches();
                }
                if (fermer) {
                    store.retour();
                }
            });
        }
    }

    saveOccupants() {
        if (this.risqueStore) {
            api.save({
                id: this.risqueStore.id,
                occupants: this.risqueStore.informations.occupants ? this.risqueStore.informations.occupants : []
            }).then(risque => {
                store.retour();
            });
        }
    }



    delete(id = null) {
        const idToDel = id ? id : this.risqueStore.id;
        const type = this.risqueStore.type;
        if (!idToDel) return false;
        api.delete(idToDel).then(() => runInAction(() => {
            this.risqueStore = null;
            this.retour();
            if (type === "peniche") {
                this.lirePeniches();
            }
            //this.rechercherEvals();
        }));
    }

    resilier(id = null, dateFin = null) {
        const idToRes = id ? id : this.risqueStore.id;
        const dateFinRes = dateFin ? dateFin : this.risqueStore.informations.dateFin;
        if (!idToRes || !dateFinRes) return false;
        api.resilier(idToRes, dateFinRes).then(() => runInAction(() => {
            this.risqueStore = null;
            this.retour();
        }));
    }


    load(id) {
        api.getRisque(id).then(docJson => runInAction(() => {

            const dateFormatMatch = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

            const formaterDateJson = (key, value) => {
                if (typeof value === "string" && dateFormatMatch.test(value)) {
                    return new Date(value);
                }

                return value;
            }

            const doc = JSON.parse(docJson, formaterDateJson);
            console.log(doc);

            this.creerRisque(doc.type);
            this.risqueStore.id = doc._id;
            this.risqueStore.acceptation = doc.acceptation;
            this.risqueStore.noSoc = doc.noSoc;
            this.risqueStore.informations = doc.informations;
            this.risqueStore.idAssociation = doc.idAssociation;
            this.risqueStore.contrat = doc.contrat;
            this.risqueStore.intercalaire = doc.intercalaire;
            this.risqueStore.commentaireMacif = doc.commentaireMacif;
            this.risqueStore.commentaireGestion = doc.commentaireGestion;
            //this.risqueStore.dateDebut=doc.dateDebut;
            //this.risqueStore.dateFin=doc.dateFin;
            this.risqueStore.dateSaisie = doc.dateSaisie;

        }));
    }

    lireRisques(tout = false,corbeilleResto=false) {
        const asso = tout ? false : this.assoChoisie;
        api.getRisques(asso,false).then(liste => runInAction(() => {
            this.listeRisque = liste.filter(r=>{
                const dtJour = new Date()
                //const dtFin = new Date(r.dtF)
                return !corbeilleResto ? true : (r.t==='appartement' && r.occ.filter(o=>!o.enfant).length===0 && (r.dtF===null || r.dtF > dtJour.toISOString()))
            }).map(r => {
                console.log(r.dtD);
                return {
                    _id: r._id,
                    acceptation: r.acpt,
                    contrat: r.ct,
                    intercalaire: r.int,
                    type: r.t,
                    dateCreation: r.dtC,
                    idAssociation: r.idAs,
                    dateDebut: r.dtD,
                    dateFin: r.dtF ? r.dtF : r.t === 'manifestation' ? (r.dtD ? new Date(new Date(r.dtD).getTime() + (60 * 60 * 24 * 1000)).toISOString() : null) : null,
                    att: r.att,
                    occupants: r.occ,
                    nbreEnfant: r.e ,
                    participants: r.part,
                    exterieur: r.ext,
                    resiliation: r.r,
                    natureActivite: r.natAct,
                    nbreLogement:r.lgmt || false,
                    description: this.descriptionRisque(r)
                }

                //return { ...r, description: this.descriptionRisque(r), dateDebut: r.informations.dateDebut, dateFin: r.informations.dateFin }
            })
        }));
    }

    lirePeniches() {
        return api.getPeniches().then(liste => runInAction(() => {
            this.listePeniche = liste.map(peniche => {
                return { value: peniche.informations.numero, label: "Peniche N° " + peniche.informations.numero + " (" + peniche.informations.nomPeniche + ")" }
            })
        }))
    }

    lireCorbeille() {
        api.getCorbeille().then(liste => runInAction(() => {
            this.listeRisque = liste.map(r => {
                return {
                    _id: r._id,
                    acceptation: r.acpt,
                    contrat: r.ct,
                    intercalaire: r.int,
                    type: r.t,
                    dateCreation: r.dtC,
                    idAssociation: r.idAs,
                    dateDebut: r.dtD,
                    dateFin: r.dtF,
                    att: r.att,
                    occupants: r.occ,
                    exterieur: r.ext,
                    resiliation: r.r,
                    natureActivite: r.natAct,
                    description: this.descriptionRisque(r)
                }
                return { ...r, description: this.descriptionRisque(r), dateDebut: r.informations.dateDebut, dateFin: r.informations.dateFin }
            })
        }));
    }

    descriptionRisque(risque) {

        if (risque.desc) return risque.desc;

        switch (risque.t) {
            case 'activite':
                const activite = this.activites.find(a => a.value === risque.natAct);
                return activite ? activite.libelle : risque.natAct;
            case 'manifestation':
                return risque.tEven;

            case 'residenceSociale':
            case 'immoVente':
            case 'appartement':

            default:
                return "";

        }
    }

    rechercherCommune(recherche) {
        api.rechercheCommunes(recherche).then(r => console.log(r));
    }

    getMoisFacturation() {
        api.getMoisFacturation().then(moisFacturation => runInAction(() => this.moisFacturation = moisFacturation));
    }

    getRestitution() {
        api.getRestitution(this.choixMoisFacturation).then(restitution => runInAction(() => this.restitution = restitution));
    }

    figerRestitution() {
        api.figerRestitution().then(() => {
            this.getRestitution();
            this.getMoisFacturation();
        });
    }

    setMoisFacturation(mois) {
        this.choixMoisFacturation = mois;
        this.getRestitution();
    }

    creerRisque(type) {
        console.log("Crééer risque", type)
        switch (type) {
            case 'activite':
                this.risqueStore = new ActiviteStore(this.assoChoisie);
                break;

            case 'manifestation':
                this.risqueStore = new ManifestationStore(this.assoChoisie);
                break;

            case 'residenceSociale':
                this.risqueStore = new ResidenceSocialeStore(this.assoChoisie);
                break;

            case 'immoVente':
                this.risqueStore = new ImmoVenteStore(this.assoChoisie);
                break;

            case 'appartement':
                this.risqueStore = new AppartementStore(this.assoChoisie);
                break;

            case 'personneResSoc':
                this.risqueStore = new PersonneResSocStore(this.assoChoisie);
                break;

            case 'localExploitation':
                this.risqueStore = new LocalExploitationStore(this.assoChoisie);
                break;

            case 'peniche':

                this.risqueStore = new PenicheStore('PNCH');
                this.risqueStore.informations.numero = this.listePeniche.length + 1;

                break;


            default:
                break;
        }

        this.goTo(this.routerStore.routerState.routeName === "corbeille" ? "majGestionnaireRisque" : "saisieRisque");
    }

    getLibelleAttestation(id) {
        return libellesAttestations.get(id);
    }



    getAttestations(risque) {
        this.attestations = ['ATT12', 'ATT13'];
        switch (risque.type) {
            case 'manifestation':
                if (risque.acceptation) {
                    if (risque.att) {
                        return (risque.idAssociation === 'NAT') ? ['ATT19'] : ['ATT18'];
                    } else if (risque.exterieur === true) {
                        return (risque.idAssociation === 'NAT') ? ['ATT10'] : ['ATT11'];
                    } else {
                        return (risque.idAssociation === 'NAT') ? ['ATT06'] : ['ATT07'];
                    }
                } else {
                    return [];
                }

            case 'peniche':
                if (risque.acceptation) {
                    return ['ATT15'];
                }

            case 'activite':
                if (risque.acceptation) {
                    return (risque.idAssociation === 'NAT') ? ['ATT05'] : ['ATT04']
                }


            case 'residenceSociale':
                return [];

            case 'immoVente':
                return [];

            case 'appartement':
                return (['ATT12', 'ATT22']);

            case 'personneResSoc':
                return [];

            case 'localExploitation':
                if (risque.acceptation) {
                    return (risque.idAssociation === 'NAT') ?
                        ((risque.att) ? ['ATT20'] : ['ATT09']) :
                        ((risque.att) ? ['ATT21'] : ['ATT09'])
                }


            default:
                return [];
        }


    }

    setFiltreTypes(filtre) {
        this.filtreTypes = filtre;
    }
}



decorate(RootStore, {
    menu: computed,
    user: observable,
    readOnly: computed,
    choixRisque: observable,
    setChoixRisque: action.bound,
    filtreTypes: observable,
    assoChoisie: observable,
    risqueStore: observable,
    listeRisque: observable,
    listePeniche: observable,
    listeCommunes: observable,
    moisFacturation: observable,
    restitution: observable,
    choixAssoAttestation: observable,
    listeCommunesLibelle: computed,
    rechercherCommunes: action.bound,
    titreAssociation: computed,
    getUser: action.bound,
    setFiltreTypes: action.bound,
    delete: action.bound,
    resilier: action.bound,
    load: action.bound,
    lireRisques: action.bound,
    lirePeniche: action.bound,
    getRestitution: action.bound,
    setChoixAssoAttestation: action.bound,
    setMoisFacturation: action.bound
    /*
    demandesClient: computed,
    setDemande: action.bound,
    */
});



const store = new RootStore();

store.getUser();
store.goTo('choixNatDep');

export default store;