import React from 'react';
import { decorate, computed, observable } from 'mobx';
import RisqueStore from '../../store/Risque';
import PersonneResSoc from './index';
/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class PersonneResSocStore extends RisqueStore {

    constructor(idAssociation){
        super(idAssociation);
        this.idAssociation=idAssociation;
        this.type = "personneResSoc";
        this.contrat = "M001";
        this.composant = <PersonneResSoc/>
        this.informations = { 
            dateDebut: null,
            dateFin: null,            
            typeResidence:null,                       
            nom:"",
            adresse: "",
            cp:"",
            commune:"",
            nbreFamilles:"",
            nbrePersonnes:""
        }
    }


    conditionsPourEnregistrable(){
        return(Number(this.informations.valeurContenu) <= 1525 || !this.informations.valeurContenu);        
    }

    messageSupplementaire(){
        return this.conditionsPourEnregistrable() ? "" : "Le plafond pour la valeur du contenu est de 1 525 €";
    }        

    setIntercalaire() {
        this.intercalaire = (this.informations.nbreFamilles) ? 21 :
                            (this.informations.nbrePersonnes) ?  22 : null;
    }    

}


export default PersonneResSocStore;