import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import BouleanStore from '../../componants/UIStore/BouleanStore';
import DateStore from '../../componants/UIStore/DateStore';
import SelectBoutonStore from '../../componants/UIStore/SelectBoutonStore';
import SelectStore from '../../componants/UIStore/SelectStore';

import { Adresse } from '../../componants/Adresse';

import { InfoContrat } from '../../componants/InfoContrat';
import { choixTypeLocal } from '../../Constantes';
import { ClauseBail } from '../../componants/ClauseBail';

import { observer, inject } from 'mobx-react';

var LocalExploitation = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;

        const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

        const choixTypeResident = [
            { label: "Locataire", value: "locataire" },
            { label: "Propriétaire", value: "proprietaire" }
            //{ label: "Copropriétaire", value: "coproprietaire" }
        ]


        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>Local d'exploitation</h1>
                <InfoContrat />
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                            <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                        </div>
                    </div>
                </div>
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <Adresse />
                        <SelectBoutonStore champ="typeOccupant" multiple={false} choix={choixTypeResident} libelle="Occupant" obligatoire={true} />
                        {store.risqueStore.get('typeOccupant') === 'locataire' &&
                            <ClauseBail />
                        }
                        <div style={{ display: 'flex' }}>
                            <SelectStore champ="typeLocal" multiple={false} choix={choixTypeLocal} libelle="Type de local" obligatoire={true} largeur="200px" />
                            {risqueStore.get("typeLocal") === "autre" &&
                                <InputStore libelle="Autre" champ="typeLocalAutre" largeur="190px" />
                            }
                            {risqueStore.get("typeLocal") === "bungalow" &&
                                <InputStore libelle="Usage" champ="usageBungalow" largeur="190px" />
                            }
                        </div>
                        <BouleanStore libelle="Occupation restreinte" champ="occupOccasionelle" />
                    </div>
                    <div style={styleBlock}>
                        <InputStore libelle="Superficie" champ="superficie" largeur="100px" unite="m²" />
                        <BouleanStore libelle="Dans bâtiment >2500m2" champ="batimentGrand" />
                        <BouleanStore libelle="Bâtiment classé/inventorié monument historique (tout ou partie)" champ="batimentClasse" />
                        <InputStore libelle="Valeur contenu" champ="valeurContenu" largeur="100px" unite="€" obligatoire={true} />
                        <div style={{ display: 'flex' }}>
                            <BouleanStore libelle="Chambre(s) froide(s)" champ="chambreFroide" />
                            {risqueStore.get("chambreFroide") &&
                                <React.Fragment>
                                    <InputStore libelle="Nbre" champ="nbreChambreFroide" largeur="100px" />
                                    <InputStore libelle="Volume" champ="volumeChambreFroide" largeur="100px" unite="M3" />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                {store.risqueStore.get('occupOccasionelle') &&
                    <div style={styleFlex}>
                        <div style={styleBlock}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <InputStore champ="detailOccupation" libelle="Détail de l'occupation" obligatoire={true} largeur="600px" majuscule={false} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );

    })
)



export default LocalExploitation;
