import React from 'react';
import { inject,observer } from 'mobx-react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {MultiSelect} from 'primereact/multiselect';

import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';

import {libellesType} from '../../Constantes';

import {api} from '../../api';

const styles = {
    root: {
        padding: 16
    }
};







export const Corbeille = inject('store')(
    observer(class Corbeille extends React.Component {    
        
        constructor(props) {
            super(props);
            this.state = {
                selectedEval: null,                           
            }      
            
            this.templateAcceptation = this.templateAcceptation.bind(this);
        }

        componentDidMount(){
            this.dt.filter(this.props.store.filtreTypes, 'type', 'in')
        }

        boutonsAttestations(risque){
            const attestations = this.props.store.getAttestations(risque).map( (attestationId,i) => <a key={i} className="petitBouton" href={api.getPdfUrl(attestationId+"/"+risque._id)} target="_blank">{this.props.store.getLibelleAttestation(attestationId)}</a>);
            const nbreEnfants = risque.informations.occupants.filter(occupant=>occupant.enfant).length
            if(risque.type==="appartement"){
                if(nbreEnfants>0){
                    attestations.push(
                        <a key={100} className="petitBouton" href={api.getPdfUrl("ATT13/"+risque._id)} target="_blank">Attestations Scolaires</a> 
                    )
                }
            }
            return (
                <React.Fragment>
                    {attestations}
                </React.Fragment>
            )
        }

        boutonsActions(risque){            
            return (<span className="petitBouton" onClick={()=>this.props.store.load(risque._id)}>
                {this.props.store.routerStore.routerState.routeName === "corbeille" ? 'Traiter':'Consulter'}
            </span>)
        }

        templateAcceptation(rowData,column){
            
            let icone = 'question-circle';
            let message = 'Attente validation';
            let couleur = 'black';
            if(rowData.resiliation){
                icone = "times-circle";
                couleur = 'red';
                message = 'Résilié';
            } else {                            
                if(rowData.acceptation === true){
                    icone = 'check';
                    couleur = 'limegreen';
                    message = 'Accepté';
                }
                if(rowData.acceptation === false){
                    icone = 'ban';
                    couleur = 'red';
                    message = 'Refusé';
                }            
            }
            return <i className={'pi pi-'+icone} style={{color:couleur}} title={message} />
        }


        render() {
            const store = this.props.store;
            const ecart = 100;
            //console.log(store.listeRisque.slice());


            const listeTypes = store.listeRisque.slice().reduce( (acc,t) => {
                if(!acc.find(tmp=>tmp.value===t.type)){
                    acc.push({value:t.type,label:libellesType.get(t.type)})
                }
                return acc;
            },[]);

            const typeFilter = <MultiSelect style={{width:'100%'}} value={store.filtreTypes} options={listeTypes} onChange={(e)=>{
                this.dt.filter(e.value, 'type', 'in');
                store.setFiltreTypes( e.value );
            }}/>

            console.log("listeTypes",listeTypes);
            const  widthDate="110px";

            const modeCorbeille = store.routerStore.routerState.routeName === "corbeille";

            const saisieRisque = store.routerStore.routerState.routeName === "risques" && store.user && store.user.type === "MACIF" ? 
            <React.Fragment>
                <Dropdown style={{width:'405px'}} value={store.assoChoisie} options={ [{label:'National',value:'NAT'},{label:'Péniche du coeur',value:'PNCH'} ,...store.associations.map(r=>{return {label:r.id + ': '+ r.nom,value:r.id}})] } onChange={(e) => {store.setChoixAsso( e.value)}} placeholder="Association"/>
                &nbsp;
                <Dropdown style={{width:'410px'}} value={store.choixRisque} options={store.listeChoixRisques.map(r=>{return {label:r.label,value:r.value}})} onChange={(e) => {store.setChoixRisque( e.value)}} placeholder="Risque à saisir"/>
                &nbsp;<Button onClick={()=>store.creerRisque(store.choixRisque)} disabled={store.assoChoisie && store.choixRisque ? '':'disabled'} label="Ajouter"/>
            </React.Fragment> : null;

            console.log(store.listeRisque.slice())

            return (
                <div style={styles.root} style={{textAlign:'center'}}> 
                    { /* <h1>{ modeCorbeille ? 'Demandes à traiter' : 'Liste risques' }</h1>  */}
                    { !modeCorbeille &&
                        <a href={api.urlApi+'export/risques'} style={{float:'left',marginLeft:'10px'}}>Export Csv</a>                    
                    }
                    {saisieRisque}
                    <DataTable value={store.listeRisque.slice()}  ref={(el) => this.dt = el} paginator={true} rows={10} rowsPerPageOptions={[5,10,20]} >                    
                            <Column field="acceptation" header="Acceptation" sortable={true} style={{width:widthDate}}  body={this.templateAcceptation} />
                            <Column field="idAssociation" header="Asso." sortable={true} filter={true} filterMatchMode="contains" style={{width:widthDate}}  />
                            <Column field="type" header="Type" sortable={true} filter={true} filterElement={typeFilter} body={(rowData, column) =>libellesType.get(rowData.type)} />
                            <Column field="description" header="Description" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="dateCreation" style={{width:widthDate}} header="Date saisie" sortable={true}  body={(rowData, column) => rowData.dateCreation ? new Date(rowData.dateCreation).toLocaleDateString() : ""}/>   
                            <Column field="dateDebut"  style={{width:widthDate}}  header="Début" sortable={true}  body={(rowData, column) => rowData.dateDebut ? new Date(rowData.dateDebut).toLocaleDateString() : ""}/>   
                            <Column field="dateFin"  style={{width:widthDate}} header="Fin" sortable={true}  body={(rowData, column) => rowData.dateFin ? new Date(rowData.dateFin).toLocaleDateString() : ""}/>      
                            <Column style={{width:'75px'}} header="" body={(rowData, column) => rowData.resiliation && (store.user && store.user.type !== 'MACIF') ? null:this.boutonsActions(rowData)}/>   
                        </DataTable>
                </div>                                    
            );
        }

        handleClick = () => {
            window.alert("En cours de développement...")
        };
    })
);