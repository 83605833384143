import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { SelectButton } from 'primereact/selectbutton';
import Occupants from '../Occupants'



export default ({ logements, risque, idRes, onSelect }) => {

    const styleBlock = { display: 'block', margin: '15px' }
    const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

    const [modeLogement, setModeLogement] = useState(true)

    const etages = logements.reduce((accu, logement) => {
        const etage = Number(logement.etage)
        if (accu.indexOf(etage) < 0) {
            accu.push(etage)
        }
        return (accu)
    }, []).sort()

    return (
        <React.Fragment>
            <div style={styleFlex}>
                <SelectButton value={modeLogement} 
                                   options={[{label:'Logements',value:true},{label:'Occupants',value:false}]}
                                   onChange={e=>setModeLogement(e.value)} />
            </div>
            {modeLogement &&
                etages.map((etage, eIdx) =>
                    <React.Fragment key={eIdx}>
                        <div style={styleFlex}>
                            <h3 style={{ marginTop: '2rem' }}>
                                {Number(etage) === 0 ? 'RDC' : etage + (Number(etage) === 1 ? 'er' : 'eme') + ' étage'}
                            </h3>
                        </div>
                        <div style={styleFlex}>
                            {logements.filter(logement => Number(logement.etage) === etage).map((logement, i) =>
                                <Card key={i}
                                    style={{ margin: '5px' }}
                                    footer={<div>
                                        <span className='petitBouton' onClick={() => onSelect(logement)}>Modifier</span>
                                    </div>}

                                    title={logement.numero + ' - ' + logement.nbrePieces + ' pièce' + (Number(logement.nbrePieces) > 1 && 's')}
                                    subTitle={logement.occupants.length === 0 ? 'Inoccupé' : logement.occupants[0].nom} >
                                    {logement.occupants.length > 0 &&
                                        logement.occupants.length + ' occupant' + (logement.occupants.length > 1 && 's')
                                    }
                                    {logement.occupants.length === 0 && <span>  </span>}
                                </Card>)}
                        </div>
                    </React.Fragment>
                )}
                {!modeLogement &&
                    <Occupants logements={logements} idRes={idRes} />
                }
        </React.Fragment>
    )
}
