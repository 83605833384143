import React from 'react';
import {Dropdown} from 'primereact/dropdown';
import { observer, inject } from 'mobx-react';

const SelectStore = inject("store")(
    observer(class SelectStore extends React.Component {

        render() {
            const risqueStore = this.props.store.risqueStore;

            const {champ,libelle,choix,largeur,index, obligatoire} = this.props;

            const valeur = risqueStore.get(champ,index);

            if(obligatoire){
                risqueStore.setChampObligatoire(champ,valeur !== null,index);
            }            

            const disabled = this.props.store.readOnly;

            return (
                <div style={{display:'block',margin:'5px'}}>
                    <label for={champ} class="frm_primary_label">{libelle} 
                        {obligatoire && <span class="frm_required" style={{color:valeur ? 'black':'red'}}> *</span> }               
                    </label>
                    <Dropdown value={valeur} options={choix} onChange={(e) => risqueStore.set(champ, e.value,index)} style={{width:largeur ? largeur : '200px'}}  disabled={disabled} />                    
                </div>
            );
        }

    })
)

export default SelectStore;
