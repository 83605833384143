import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown'


const InputLibelle = ({ champ, valeur, majValeur, libelle, largeur }) => {
    console.log(champ, valeur, majValeur, libelle, largeur)
    return (
        <div style={{ display: 'block', margin: '5px' }}>
            <label for={champ} class="frm_primary_label">{libelle}
                <span class="frm_required" style={{ color: valeur ? 'black' : 'red' }}> *</span>
            </label>
            <div className="p-inputgroup">
                <InputText id={champ} value={valeur} onChange={e => majValeur(e.target.value)} style={{ width: largeur, height: '38px' }} />
            </div>
        </div>
    )
}


export default ({ logement, saveLogement, nbreEtages, listeNumeros }) => {

    console.log(logement, nbreEtages)
    const choixEtages = [{ value: 0, label: 'RDC' }]

    for (let i = 1; i <= Number(nbreEtages); i++) {
        choixEtages.push({ value: i, label: i + (i === 1 ? 'er' : 'eme') })
    }

    const [modeAjout, setModeAjout] = useState(false)
    const [numero, setNumero] = useState(logement.numero || '')
    const [etage, setEtage] = useState(logement.etage || '')
    const [nbrePieces, setNbrePieces] = useState(logement.nbrePieces || '')
    const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

    if (!modeAjout) {
        return (<div style={styleFlex}>
            <Button label='Ajouter un logement' onClick={() => setModeAjout(true)} />
        </div>)
    } else {
        return (
            <React.Fragment>
                <div style={styleFlex}>

                    <InputLibelle champ='numero' libelle='Numéro' valeur={numero} majValeur={setNumero} largeur='40px' />
                    <div style={{ display: 'block', margin: '5px' }}>
                        <label for='etage' class="frm_primary_label">Etage
                    <span class="frm_required" style={{ color: 'red' }}> *</span>
                        </label>
                        <div className="p-inputgroup">
                            <Dropdown id='etage' value={etage} options={choixEtages} onChange={(e) => setEtage(e.target.value)} largeur='80px' />
                        </div>
                    </div>

                    <InputLibelle champ='nbrePieces' libelle='Nombre de pièces' valeur={nbrePieces} majValeur={setNbrePieces} largeur='40px' />
                </div>
                {listeNumeros.indexOf(numero) >= 0 &&
                    <div style={styleFlex}>
                        <span style={{color:'red'}} >Numéro de logement existant</span>
                    </div>
                }
                <div style={styleFlex}>
                    <Button label='Ajouter' onClick={() => {
                        saveLogement({ numero: numero, etage: etage, nbrePieces: nbrePieces })
                        setModeAjout(false)
                        setNumero('')
                    }} disabled={!(numero && listeNumeros.indexOf(numero) < 0 && etage!==null && Number(nbrePieces)>0 )} />
                    &nbsp;
                    <Button label='Annuler' onClick={() => setModeAjout(false)} />
                </div>
            </React.Fragment>
        )
    }
}
