import React from 'react';
import { inject } from 'mobx-react';


import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';


import './style.css';

const styles = {
    root: {
        padding: 16
    }
};

export const ChoixNatDep = inject('store')(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                globalFilter: "",
                choixDep: false
            }
        }

        render() {
            const store = this.props.store;

            const ecart = 100;
            const header = <div style={{ 'textAlign': 'left' }}>
                <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche" size="10" />
            </div>;

            return (
                <div style={styles.root} style={{ textAlign: 'center' }}>
                    <br /><br /><br /><br />
                    {!this.state.choixDep &&
                        <React.Fragment>
                            Je me connecte en tant que :<br/><br/>
                            <button className="bouton-choix" onClick={() => store.setChoixAsso('NAT')}>Association<br/>Nationale</button>
                            <button className="bouton-choix" onClick={() =>this.setState({choixDep:true}) }>Association<br/>Départementale</button>
                            <button className="bouton-choix" onClick={() => store.setChoixAsso('PNCH')}>Péniche du<br/>Coeur</button>                            
                        </React.Fragment>
                    }
                    {this.state.choixDep &&
                        <div className="row">
                            <div className="columns expand small-12 medium-2 large-1"></div>
                            <div className="columns expand small-12 medium-12 large-10">
                                <DataTable value={store.associations} style={{ width: 'auto' }}
                                    paginator={true} rows={10} header={header}
                                    globalFilter={this.state.globalFilter} 
                                    selectionMode="single" onSelectionChange={e => store.setChoixAsso( e.value.id)}>
                                    <Column field="id" header="ID" style={{ width: "70px" }} />
                                    <Column field="nom" header="Nom" />
                                </DataTable>
                            </div>
                            <div className="columns expand small-12 medium-2 large-1"></div>
                        </div>
                    }
                </div>
            );
        }

        handleClick = () => {
            window.alert("En cours de développement...")
        };
    }
);