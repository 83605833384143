import React from 'react';
import { facturerOui, facturerNon, dateEnFrancais } from '../../../Constantes';


export class LocauxExploitation extends React.Component {


    render() {
        if (!this.props.risques || this.props.risques.length === 0) return null
        return (
            <React.Fragment>
                <tr>
                    <th className='nom-risque' colSpan="16">Assurer un local d'exploitation</th>
                </tr>
                <tr>
                    <th>N° Assuré</th>
                    <th>N° Contrat</th>
                    <th>N° Intercalaire</th>
                    <th>ID Asso</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>adresse</th>
                    <th>Qualité d'occupant</th>
                    <th>Type local</th>
                    <th>Usage&nbsp;si bungalow</th>
                    <th>Surface</th>
                    <th>Située&nbsp;dans un&nbsp;bâtiment >2500m²</th>
                    <th>bâtiment classé/inventorié monument&nbsp;historque, en&nbsp;tout&nbsp;ou&nbsp;partie</th>
                    <th>Nombre chambres&nbsp;froides</th>
                    <th>Valeur contenu</th>
                    <th className='facturation'></th>
                </tr>
                {this.props.risques.map((risque, i) => <tr key={i}>
                    <td>{risque.noSoc}</td>
                    <td>{risque.contrat}</td>
                    <td>{risque.intercalaire}</td>
                    <td>{risque.idAssociation}</td>
                    <td>{dateEnFrancais(risque.dateDebut)}</td>
                    <td>{ this.props.mois === risque.moisSortieFacturation && dateEnFrancais(risque.dateFin)}</td>
                    <td>{risque.adresse}</td>
                    <td>{risque.typeOccupant}</td>
                    <td>{risque.typeLocal}</td>
                    <td>{risque.usageBungalow}</td>
                    <td>{risque.superficie}</td>
                    <td>{risque.batimentGrand}</td>
                    <td>{risque.batimentClasse}</td>
                    <td>{risque.nbreChambreFroide}</td>
                    <td>{risque.valeurContenu ? Number(risque.valeurContenu).toLocaleString()+" €" : "" }</td>
                    <td className='facturation'>{risque.facturer ? facturerOui : facturerNon}</td>
                </tr>)}
            </React.Fragment>
        )
    }


}