import React from 'react';
import { InputText } from 'primereact/inputtext';
import { observer, inject } from 'mobx-react';

const InputStore = inject("store")(
    observer(class InputStore extends React.Component {


        maj(champ,valeur,index){
            if(this.props.regex){
                if(!valeur.match(this.props.regex)&&valeur>''){
                    return false;
                }
            }
            this.props.store.risqueStore.set(champ, this.props.majuscule===false ? valeur : valeur.toUpperCase(),index);
        }

        componentWillUnmount(){
            if(this.props.obligatoire && this.props.store.risqueStore){
                this.props.store.risqueStore.setChampObligatoire(this.props.champ,true);
            }
        }

        render() {
            const risqueStore = this.props.store.risqueStore;

            const {champ,largeur,libelle,obligatoire} = this.props;

            const hauteur = { height: '2.3125rem' }

            const index = typeof(this.props.index) === "number" ? this.props.index : null;     
            
            const valeur = risqueStore.get(champ,index);

            if(obligatoire){
                risqueStore.setChampObligatoire(champ,valeur > '',index);
            }

            const disabled = this.props.readOnly === false ? false : this.props.store.readOnly;

            return (
                <div style={{display:'block',margin:'5px'}}>
                    <label for={champ} class="frm_primary_label">{libelle}
                    {obligatoire && <span class="frm_required" style={{color:valeur ? 'black':'red'}}> *</span> }
                    </label>
                    <div className="p-inputgroup">                    
                        <InputText type="text" id={champ} value={valeur} onChange={e => this.maj(champ, e.target.value,index)} style={{ width: largeur }} disabled={disabled} />
                        { typeof(this.props.unite)==="string" &&
                            <span className="p-inputgroup-addon" style={hauteur}>{this.props.unite}</span>
                        }
                    </div>
                </div>
            );
        }

    })
)

export default InputStore;


