import React from 'react';
import { decorate, computed, observable } from 'mobx';
import RisqueStore from '../../store/Risque';
import Manifestion from './index';
/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class ManifestationStore extends RisqueStore {

    constructor(idAssociation){
        super(idAssociation);
        this.idAssociation=idAssociation;
        this.type = "manifestation";
        this.contrat = "S001";        
        this.composant = <Manifestion/>
        this.acceptation = null;
        this.informations = {
            dateDebut: null,
            dateFin: null,
            exterieur: null,
            nomSalle : "",
            adresse: "",
            cp:"",
            commune:"",
            nombreParticipants:"",
            superficie:"",
            batimentClasse:null,
            typeEvenement:"",
            batimentGrand:null
        }
        this.messageSystematique = "Si la manifestation a lieu sur la voie publique, assurez-vous d’être en possession de toutes les autorisations administratives";
        //this.contrat=null;
        //this.intercalaire=null;

    }

    setIntercalaire() {
        this.intercalaire = ( 
            Number(this.informations.nombreParticipants)<600 && 
            Number(this.informations.superficie)<2500  && 
            !this.informations.batimentClasse && 
            !this.informations.batimentGrand 
        ) ? 1 : 2;
                            
    }

    setAcceptation() {
        
        const nombreParticipants = Number(this.informations.nombreParticipants);
        const superficie = Number(this.informations.superficie);
        const {batimentClasse,batimentGrand} = this.informations;
        const {typeEvenement } = this.informations;
        console.log("setAffectation",this.informations,nombreParticipants,superficie);        
        if(this.informations.exterieur===true || ( nombreParticipants>0 && nombreParticipants < 600 && superficie >0 && superficie < 2500 && !batimentClasse && !batimentGrand) ) {
            this.acceptation = true;
        } else {
            this.acceptation = null;
            this.messageAttenteAcceptation = "Transmettre le bail ou la convention d’occupation à restos_du_coeur@macif.fr";
        }

        

        if( typeEvenement === "autre" || typeEvenement === "moto" || typeEvenement === "enchere" ){
            this.acceptation = null;
            this.messageAttenteAcceptation = "";
        }
    }



}



export default ManifestationStore;