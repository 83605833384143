import React from 'react';
import { inject } from 'mobx-react';


import './style.css';

const styles = {
    root: {
        padding: 16
    }
};

export const Assurer = inject('store')(
    class extends React.Component {

        render() {
            const ecart = 100;
            const store = this.props.store;


            return (
                <div style={styles.root} style={{ textAlign: 'center' }}>
                    <h1>Je souhaite assurer :</h1>

                    <div style={{ display: 'flex', padding: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>                        
                        { store.listeChoixRisques.map(choix => choix.bouton) }
                    </div>
                </div>
            );
        }

        handleClick = () => {
            window.alert("En cours de développement...")
        };
    }
);



