import React from 'react';
import { inject, observer } from 'mobx-react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';



export const Users = inject('store')(
    observer(class Users extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                username:'',
                nom:'',
                prenom:'',
                password:'',
                idAsso:null
            }
        }


        register(){
            console.log(this.state);
            this.props.store.adminStore.register(this.state.username,this.state.nom,this.state.prenom,this.state.idAsso,this.state.password);
        }

        delete(userId){
            if(window.confirm("Voulez vous vraiment supprimer cet utilisateur ?")){
                this.props.store.adminStore.deleteUser(userId);
            }
        }

        templateAction(user){
            return (
                <span className="petitBouton" onClick={()=>this.delete(user._id)}>
                    Supprimer
                </span>
            )
        }

        render() {
            const store = this.props.store;

            const associations = [{label:'Nationale',value:'NAT'}, ...store.associations.map(asso => {
                return {
                    label: asso.id + ": " +asso.nom,
                    value:asso.id
                }
            })]

            const actif= this.state.username > '' && this.state.nom > '' && this.state.prenom > '' && this.state.idAsso && this.state.password > ''

            console.log(this.state)

            

            return (
                <div>
                    <DataTable value={store.adminStore.users.slice()} ref={(el) => this.dt = el} paginator={true} rows={10} rowsPerPageOptions={[5, 10, 20]} >
                        <Column field="username" header="eMail" sortable={true} />
                        <Column field="nom" header="Nom" sortable={true} />
                        <Column field="prenom" header="Prénom" sortable={true} />
                        <Column field="idAsso" header="Id Asso" sortable={true} />
                        <Column field="_id" header="Action" body={(rowData, column) => this.templateAction(rowData)} />
                    </DataTable>
                    <br/><br/>
                    <InputText value={this.state.username} placeholder="Adresse eMail" onChange={(e) => this.setState({username: e.target.value})}></InputText>
                    &nbsp;
                    <InputText value={this.state.nom} placeholder="Nom" onChange={(e) => this.setState({nom: e.target.value})}></InputText>
                    &nbsp;
                    <InputText value={this.state.prenom} placeholder="Prénom" onChange={(e) => this.setState({prenom: e.target.value})}></InputText>                    
                    &nbsp;
                    <InputText value={this.state.password} placeholder="Mot de passe" onChange={(e) => this.setState({password: e.target.value})}></InputText>                    
                    &nbsp;
                    <Dropdown value={this.state.idAsso} options={associations.slice()} onChange={(e) =>  this.setState({idAsso:e.value})} style={{width: '200px'}}  />                    
                    &nbsp;
                    <Button label="Ajouter"
                        icon="pi pi-plus"
                        onClick={() => this.register()}
                        disabled={ !actif ? 'disabled' : ''}
                    />
                </div>
            );
        }

    })
);