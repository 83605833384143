import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import BouleanStore from '../../componants/UIStore/BouleanStore';
import DateStore from '../../componants/UIStore/DateStore';
import SelectStore from '../../componants/UIStore/SelectStore';
import { Adresse } from '../../componants/Adresse';
import { InfoContrat } from '../../componants/InfoContrat';
import { ClauseBail } from '../../componants/ClauseBail';

import { listeTypeManifestation } from '../../Constantes';

import { observer, inject } from 'mobx-react';


var Manifestation = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;

        const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

        // extérieur , batimentClasse:null,
        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>Manifestation</h1>
                <InfoContrat />
                <div style={styleFlex}>
                    <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                    <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                </div>
                <div style={styleFlex}>
                    <BouleanStore vrai="Sans salle" faux="Avec salle" libelle={""} champ="exterieur" obligatoire={true} />
                </div>

                {risqueStore.get('exterieur') === false &&
                    <div style={styleFlex}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={styleBlock}>
                                <InputStore libelle="Nom de la salle" champ="nomSalle" largeur="400px" />
                                <Adresse />
                            </div>

                            <div style={styleBlock}>
                                <InputStore libelle="Nombre de participants" champ="nombreParticipants" largeur="100px" obligatoire={true} />
                                <InputStore libelle="Superficie" champ="superficie" largeur="100px" unite="m²" />
                                <BouleanStore libelle="Dans bâtiment >2500m2" champ="batimentGrand" obligatoire={true} />
                                <BouleanStore libelle="Bâtiment classé/inventorié monument historique (tout ou partie)" champ="batimentClasse" obligatoire={true} />
                                <ClauseBail />
                                <SelectStore champ="typeEvenement" multiple={false} choix={listeTypeManifestation} libelle="Type d'évenement" obligatoire={true} largeur="300px" />
                                {risqueStore.get("typeEvenement") === "autre" &&
                                    <InputStore champ="descriptif" libelle="Descriptif événement" obligatoire={true} largeur="300px" majuscule={false} />
                                }
                            </div>

                        </div>
                    </div>
                }
                {risqueStore.get('exterieur') === true &&
                    <React.Fragment>
                        <div style={styleFlex}>
                            <div style={styleBlock}>
                                <SelectStore champ="typeEvenement" multiple={false} choix={listeTypeManifestation} libelle="Type d'évenement" obligatoire={true} largeur="300px" />

                            </div>
                        </div>
                        <div style={styleFlex}>
                            <div style={styleBlock}>
                                <InputStore champ="descriptif" libelle="Descriptif de l'événement" obligatoire={true} largeur="1000px" majuscule={false} />
                            </div>
                        </div>
                        <div style={styleFlex}>
                            <div style={styleBlock}>
                                <BouleanStore libelle="Soumise à autorisation préfectorale" champ="autorisationPref" obligatoire={true} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>

        );

    })
)


export default Manifestation;
