import React from 'react';
//import { decorate, observable, action, computed, reaction, ObservableMap } from 'mobx';
import RisqueStore from '../../store/Risque';
import Appartement from './index';
/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class AppartementStore extends RisqueStore {

    constructor(idAssociation){
        super(idAssociation);
        this.idAssociation=idAssociation;
        this.type = "appartement";
        this.contrat = "M001";
        this.intercalaire = "20";           
        this.composant = <Appartement/>
        this.acceptation = true;
        this.informations = {
            dateDebut: null,
            dateFin: null,                                    
            adresse: "",
            cp:"",
            commune:"",
            typeOccupant:"",                        
            superficie:"",
            nbrePieces:"",
            valeurContenu:"", 
            nom:"",
            prenom:"",
            nomConjoint:"",
            prenomConjoint:"",
            occupants:[]
        }
    }

    get adultes(){
        return this.informations.occupants.filter(o=>!o.enfant)
    }

    conditionsPourEnregistrable(){
        return(Number(this.informations.valeurContenu) <= 18402 && this.adultes.length > 0);        
    }

    messageSupplementaire(){
        if(this.conditionsPourEnregistrable()){
            return ""
        }

        return this.adultes.length === 0 ? "Il faut au moin un occupant adulte" : "Le plafond pour la valeur du contenu est de 18 402 €"
        //return this.conditionsPourEnregistrable() ? "" : "Le plafond pour la valeur du contenu est de 18 402 €";
    }    

}


export default AppartementStore;