import { decorate, observable, action, computed, reaction, ObservableMap, runInAction } from 'mobx';
import { api } from '../apiAdmin';
/*
La class RisqueStore n'est pas utilisée directement.
Elle est dérivée pour les stores spécifiques de chaque risque.
*/
class AdminStore {

    users = [];

    constructor(rootStore){        
        this.rootStore = rootStore;
    }

    getUsers(){
        api.getUsers().then( users => this.users = users);
    }

    register(username,nom,prenom,idAsso,password){

        api.register(username,nom,prenom,idAsso,password).then( res => runInAction( () =>{
            this.getUsers();
        }));
    }

    deleteUser(userId){
        api.deleteUser(userId).then(rep=>{
            this.getUsers();
        })
    }

}


decorate(AdminStore, {
    users: observable,
    getUsers: action.bound,
    register:action.bound
});

export default AdminStore;