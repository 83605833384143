import React from 'react';
import { inject } from 'mobx-react';

import {api} from '../../api';
 
import {Button} from 'primereact/button';

const styles = {
    root: {
        padding: 16
    }
};

export const ChangePswd = inject('store')(
    class extends React.Component {

        constructor(props){
            super(props);
            this.state = {
                oldPswd:'',
                newPswd:''
            }
        }
        
        render() {
            const ecart = 100;
            return (
                <div style={styles.root} style={{textAlign:'center'}}> 
                    <h1>Modification du mot de passe</h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', color: 'red' }}>
                    Ancien mot de passe : <input type='text' value={this.state.oldPswd} onChange={e=>this.setState({oldPswd:e.target.value})}/><br/>
                    Nouveau mot de passe : <input type='text' value={this.state.newPswd} onChange={e=>this.setState({newPswd:e.target.value})}/><br/>
                    {this.state.oldPswd} / {this.state.newPswd}
                    <br/>
                    <Button onClick={()=>api.changePswd(this.state.oldPswd,this.state.newPswd)} value='Modifier'/>
                    </div>
                    
                </div>
            );
        }

        handleClick = () => {
            window.alert("En cours de développement...")
        };
    }
);