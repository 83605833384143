import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { observer, inject } from 'mobx-react';

const SelectBoutonStore = inject("store")(
    observer(class SelectBoutonStore extends React.Component {

        componentWillUnmount() {
            if (this.props.obligatoire && this.props.store.risqueStore) {
                this.props.store.risqueStore.setChampObligatoire(this.props.champ, true);
            }
        }

        render() {
            const risqueStore = this.props.store.risqueStore;

            const { champ, libelle, choix, multiple, obligatoire } = this.props;
            const disabled = this.props.store.readOnly;
            const index = typeof (this.props.index) === "number" ? this.props.index : null;
            const valeur = risqueStore.get(champ, index);

            if (obligatoire) {
                risqueStore.setChampObligatoire(champ, valeur > '', index);
            }
            

            return (
                <div style={{ display: 'block', margin: '5px' }}>
                    {libelle > '' &&
                        <label for={champ} class="frm_primary_label">{libelle}
                            {obligatoire && <span class="frm_required" style={{ color: valeur ? 'black' : 'red' }}> *</span>}
                        </label>
                    }
                    <SelectButton value={risqueStore.get(champ)} options={choix} multiple={multiple} onChange={(e) => risqueStore.set(champ, e.value, index)} disabled={disabled} />
                </div>
            );
        }

    })
)

export default SelectBoutonStore;
