import React from 'react';
import { facturerOui, facturerNon, dateEnFrancais } from '../../../Constantes';


export class PersonnesResSoc extends React.Component {


    render() {
        if (!this.props.risques || this.props.risques.length === 0) return null
        return (
            <React.Fragment>
                <tr>
                    <th className='nom-risque' colSpan="16">Assurer les personnes hébergées en résidence sociale, hôtel social</th>
                </tr>
                <tr>
                    <th>N° Assuré</th>
                    <th>N° Contrat</th>
                    <th>N° Intercalaire</th>
                    <th>ID Asso</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>adresse</th>
                    <th>Nom</th>
                    <th>Nombre&nbsp;de familles</th>
                    <th>Nombre&nbsp;de personnes</th>
                    <th colSpan='5'></th>
                    <th className='facturation'></th>
                </tr>
                {this.props.risques.map((risque, i) => <tr key={i}>
                    <td>{risque.noSoc}</td>
                    <td>{risque.contrat}</td>
                    <td>{risque.intercalaire}</td>
                    <td>{risque.idAssociation}</td>
                    <td>{dateEnFrancais(risque.dateDebut)}</td>
                    <td>{ this.props.mois === risque.moisSortieFacturation && dateEnFrancais(risque.dateFin)}</td>
                    <td>{risque.adresse}</td>
                    <td>{risque.nom}</td>
                    <td>{risque.nbreFamilles}</td>
                    <td>{risque.nbrePersonnes}</td>
                    <td colSpan="5"></td>
                    <td className='facturation'>{risque.facturer ? facturerOui : facturerNon}</td>
                </tr>)}
            </React.Fragment>
        )
    }


}