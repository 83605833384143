import React from 'react';
import { inject, observer } from 'mobx-react';


export const InfoContrat = inject('store')(
    observer(class InfoContrat extends React.Component {
        render() {
            const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }
            const store = this.props.store;
            const risque = store.risqueStore;
            return (
                <div style={styleFlex}>
                    Numéro de sociétaire :&nbsp;<b>{risque.noSoc}</b>&nbsp;&nbsp; Contrat :&nbsp;<b>{risque.contrat}</b>&nbsp;&nbsp;   Intercalaire :&nbsp;<b>{risque.intercalaire}</b>
                </div>

            );
        }

    })
);


