import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import SelectBoutonStore from '../../componants/UIStore/SelectBoutonStore';
import DateStore from '../../componants/UIStore/DateStore';
import { Adresse } from '../../componants/Adresse';
import { InfoContrat } from '../../componants/InfoContrat';

import { observer, inject } from 'mobx-react';

var PersonneResSoc = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;

        const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

        const choixTypeResidence = [
            { label: "Résidence sociale", value: "residenceSoc" },
            { label: "Hôtel social", value: "hotelSoc" }
        ]


        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>
                    { risqueStore.informations.typeResidence === null ? "Occupants d'une résidence sociale ou d'un hôtel social" :
                      risqueStore.informations.typeResidence === "residenceSoc" ? "Occupants d'une résidence sociale" : "Occupants d'un hôtel social" 
                    }
                </h1>
                <InfoContrat />
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                            <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                        </div>
                    </div>
                </div>
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <InputStore libelle="Nom de la résidence" champ="nom" largeur="400px" obligatoire={true} />
                        <Adresse />
                    </div>
                    <div style={styleBlock}>
                        <SelectBoutonStore champ="typeResidence" multiple={false} choix={choixTypeResidence} libelle="Type" obligatoire={true} />
                        {risqueStore.informations.typeResidence === "residenceSoc" &&
                            <React.Fragment>
                                <InputStore libelle="Nbe de familles (contrat habitation et garantie accidents uniquement)" obligatoire={true} champ="nbreFamilles" largeur="100px" />
                                <InputStore libelle="Valeur contenu" champ="valeurContenu" largeur="100px" unite="€" obligatoire={true} regex={/^[0-9]{0,5}$/} />
                            </React.Fragment>
                        }
                        {risqueStore.informations.typeResidence === "hotelSoc" &&
                            <InputStore libelle="Nbe de personnes si hôtel social (garantie RC vie privée uniquement)" obligatoire={true} champ="nbrePersonnes" largeur="100px" />
                        }
                    </div>

                </div>
            </div>
        );

    })
)


export default PersonneResSoc;
