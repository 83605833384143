import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'mobx-react';
import { HistoryAdapter } from 'mobx-state-router';
import { createBrowserHistory } from 'history';
import  store  from './store';

import './index.css';
import {App} from './App';


import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

//import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

/*
if (process.env.NODE_ENV !== "development") {
    document.domain="macif.fr";
} 
*/

window._store_ = store;

// Observe history changes
const historyAdapter = new HistoryAdapter(store.routerStore, history);
historyAdapter.observeRouterStateChanges();

ReactDOM.render(    
    <Provider store={store} >        
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
