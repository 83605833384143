import React from 'react';
import { inject, observer } from 'mobx-react';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import './style.css';
import { Activites } from './liste/Activites';
import { Manifestions } from './liste/Manifestations';
import { AssociationsDep } from './liste/AssociationsDep';
import { ResidencesSociales } from './liste/ResidencesSociales';
import { ImmosVentes } from './liste/ImmosVentes';
import { Appartements } from './liste/Appartements';
import { PersonnesResSoc } from './liste/PersonnesResSoc';
import { LocauxExploitation } from './liste/LocauxExploitation';




const styles = {
    root: {
        padding: 16
    }
};



export const Restitution = inject('store')(
    observer(class extends React.Component {

        figer() {
            alert('Pas encore activé');
            return false;
            if(!window.confirm("Voulez-vous vraiment figer la restitution de ce mois ?")){
                return false;                
            }
            this.props.store.figerRestitution();            
        }

        render() {
            const restitution = this.props.store.restitution;
            const store = this.props.store;

            const moisFacturation = store.moisFacturation.filter(m=>m!=='INIT').map(m=>{
                return { label:m, value:m}
            });
            moisFacturation.unshift({label:'Actuel',value:null});
            moisFacturation.push({label:'INIT',value:'INIT'});

            //console.log("restitution", moisFacturation);
            if (restitution === null) return null;
            

            return (
                <React.Fragment>
                    <Dropdown value={store.choixMoisFacturation} options={moisFacturation} onChange={(e) => store.setMoisFacturation( e.value)} style={{ width: '200px' }} />
                    <Button label="Figer le mois" onClick={() => this.figer()} style={{marginLeft:'10px'}} />
                    <table className='restitution'>
                        <tbody>

                            <AssociationsDep risques={restitution.ad} mois={store.choixMoisFacturation}/>

                            <Activites risques={restitution.activite} mois={store.choixMoisFacturation} />

                            <Manifestions risques={restitution.manifestation}  mois={store.choixMoisFacturation} />

                            <ResidencesSociales risques={restitution.residenceSociale}  mois={store.choixMoisFacturation}/>

                            <ImmosVentes risques={restitution.immoVente}  mois={store.choixMoisFacturation}/>

                            <Appartements risques={restitution.appartement} mois={store.choixMoisFacturation} />

                            <PersonnesResSoc risques={restitution.personneResSoc} mois={store.choixMoisFacturation} />

                            <LocauxExploitation risques={restitution.localExploitation}  mois={store.choixMoisFacturation}/>

                        </tbody>
                    </table>
                </React.Fragment>
            );
        }

    })
);