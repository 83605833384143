import React from 'react';
import { observer, inject } from 'mobx-react';
import { RouterView } from 'mobx-state-router';
import { Menu } from './componants/menu';
import { Logo } from './componants/logo';
import { ChoixNatDep } from './page/ChoixNatDep';
import { Assurer } from './page/Assurer';
import { Attestation } from './page/Attestation';
import { Corbeille } from './page/Corbeille';
import { Login } from './page/Login';
import { Admin } from './page/Admin';
import { Aide } from './page/Aide';
import { SaisieRisque } from './page/SaisieRisque';
import { MajGestRisque } from './page/MajGestRisque';

import { NotFoundPage } from './componants/not-found-page';

import './App.css';
import { Restitution } from './page/Restitution';
import { ChangePswd } from './page/ChangePswd';

const viewMap = {
    choixNatDep: <ChoixNatDep />,
    assurer: <Assurer />,
    attestation: <Attestation />,
    corbeille: <Corbeille />,
    risques: <Corbeille />,
    login: <Login />,
    aide: <Aide />,
    saisieRisque: <SaisieRisque />,
    majGestionnaireRisque: <MajGestRisque />,
    admin: <Admin/>,
    restitution: <Restitution/>,
    corbeilleResto: <Corbeille/>,
    changePswd: <ChangePswd />,
    notFound: <NotFoundPage />
};


export const App = inject('store')(
    observer(class extends React.Component {
        render() {
            const { store } = this.props;
            const { routerStore } = store;

            return (
                <div>
                    {routerStore.routerState.routeName !== "login" &&
                        <React.Fragment>
                            { this.props.store.user !== null &&
                                <span className="widget-header--quicklink widget-header--don-link pct-login-rose pct-size-xs t-medium t-grey" style={{ position: 'absolute', right: '100px' }}>
                                    { this.props.store.user.prenom } { this.props.store.user.nom } 
                                </span>
                            }

                            { this.props.store.user !== null && this.props.store.user.type !== 'MACIF' &&
                                <React.Fragment>
                                <a href="/auth/logout" className="widget-header--quicklink widget-header--don-link pct-login-rose pct-size-xs t-medium t-grey" style={{ position: 'absolute', right: '10px' }}>
                                    Déconnexion
                                </a>
                                <a href="/changePswd" className="widget-header--quicklink widget-header--don-link pct-login-rose pct-size-xs t-medium t-grey" style={{ position: 'absolute', right: '10px' }}>
                                    pswd
                                </a>
                                </React.Fragment>
                            }
                            <header className="header js-header" role="banner"  >
                                <Logo />
                                <Menu style={{ top: '-20px' }} />
                            </header>
                        </React.Fragment>
                    }
                    <RouterView routerStore={routerStore} viewMap={viewMap} />
                </div>
            );
        }
    })
);