import React from 'react';
import { inject } from 'mobx-react';


import {Button} from 'primereact/button';

const styles = {
    root: {
        padding: 16
    }
};

export const Aide = inject('store')(
    class extends React.Component {
        
        render() {
            const ecart = 100;
            return (
                <div style={styles.root} style={{textAlign:'center'}}> 
                    <h1>Aide</h1>
                </div>
            );
        }

        handleClick = () => {
            window.alert("En cours de développement...")
        };
    }
);