import { RouterState } from 'mobx-state-router';

const checkForUserSignedIn = (fromState, toState, routerStore) => {
    const user = routerStore.rootStore.user;
    console.log("routerStore.rootStore",routerStore.rootStore);
    console.log("user",user);
    if (user) {
        return Promise.resolve();
    } else {
        //authStore.setSignInRedirect(toState);
        return Promise.reject(new RouterState('login'));
    }
};

export const routes = [
    {
        name: 'choixNatDep',
        pattern: '/',
        beforeEnter: checkForUserSignedIn
    },    
    {
        name: 'assurer',
        pattern: '/assurer'
    },
    {
        name: 'attestation',
        pattern: '/attestation',
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState, toState, routerStore) => {
            routerStore.rootStore.lireRisques(routerStore.rootStore.assoChoisie === 'NAT');
            return Promise.resolve();
        }
    },    
    {
        name: 'risques',
        pattern: '/stockRisques',
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState, toState, routerStore) => {
            routerStore.rootStore.lireRisques(true);
            return Promise.resolve();
        }
    },  
    {
        name: 'corbeille',
        pattern: '/corbeille',
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState, toState, routerStore) => {
            routerStore.rootStore.lireCorbeille(true);
            return Promise.resolve();
        }
    },  
    {
        name: 'corbeilleResto',
        pattern: '/corbeilleResto',
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState, toState, routerStore) => {
            routerStore.rootStore.lireRisques(true,true);
            return Promise.resolve();
        }
    },  
    {
        name: 'saisieRisque',
        pattern: '/saisieRisque',
        beforeEnter: checkForUserSignedIn
    },
    {
        name: 'majGestionnaireRisque',
        pattern: '/majGestRisque',
        beforeEnter: checkForUserSignedIn
    },   
    {
        name: 'login',
        pattern: '/login'
    },     
    {
        name: 'admin',
        pattern: '/admin',        
        onEnter: (fromState, toState, routerStore) => {
            routerStore.rootStore.adminStore.getUsers();
            return Promise.resolve();
        }        
    },     
    {
        name: 'restitution',
        pattern: '/restitution',        
        onEnter: (fromState, toState, routerStore) => {
            routerStore.rootStore.getMoisFacturation();
            routerStore.rootStore.getRestitution();
            return Promise.resolve();
        }        
    },    
    {
        name: 'aide',
        pattern: '/aide'
    },
    {
        name: 'changePswd',
        pattern: '/changePswd'
    },
    {
        name: 'notFound',
        pattern: '/not-found'
    }
];