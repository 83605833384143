import React from 'react';
import { RadioButton } from 'primereact/radiobutton';


import { observer, inject } from 'mobx-react';



export const ClauseBail = inject('store')(
    observer(class ClauseBail extends React.Component {



        clickRadio( valeur ) {
            if(this.props.store.readOnly) return false;
            this.props.store.risqueStore.set('clauseBail', valeur);
        }

        render() {            
            const clauseBail = this.props.store.risqueStore.informations.clauseBail;

            return (
                <div className="content-section implementation">
                    <div className="p-grid" style={{width:'250px',marginTop:'5px'}}>                    
                        <div className="p-col-12">
                            Clause bail (cochez) :
                        </div>
                        <div className="p-col-12">
                            <RadioButton inputId="cr1" name="clauseBail" value="aucune" onChange={(e) => this.clickRadio( e.value )} checked={clauseBail === 'aucune'} />
                            <label htmlFor="rb1" className="p-radiobutton-label">Aucune renonciation à recours</label>
                        </div>
                        <div className="p-col-12">
                            <RadioButton inputId="cr2" name="clauseBail" value="unilaterale" onChange={(e) =>  this.clickRadio( e.value )} checked={clauseBail === 'unilaterale'} />
                            <label htmlFor="rb2" className="p-radiobutton-label">Renonciation à recours unilatérale</label>
                        </div>
                        <div className="p-col-12">
                            <RadioButton inputId="cr3" name="clauseBail" value="reciproque" onChange={(e) =>  this.clickRadio( e.value )} checked={clauseBail === 'reciproque'} />
                            <label htmlFor="rb3" className="p-radiobutton-label">Renonciation à recours réciproque</label>
                        </div>
                    </div>                    
                </div>
            );
        }
    })
)
