import React from 'react';
import { inject, observer } from 'mobx-react';

const styles = {
    root: {
        padding: 16
    }
};

export const Menu = inject('store')(
    observer(class Menu extends React.Component {
        render() {
            const store = this.props.store;
            return (
                <div className="nav-group js-navbar"  style={{top:'-25px'}}>
                    <div className="row nav-group--inner">
                        <nav className="nav column" role="navigation">
                            <ul className="header-menu menu js-menu">
                                {store.menu.filter((item)=>item.visible).map((item, i) =>
                                    <li key={i} className={"header-menu--menu-item menu-item menu-item-type-post_type menu-item-object-page menu-item-home "+ (item.actif ? "current-menu-item":"") +" page_item  current_page_item"}
                                        onClick={()=>store.goTo(item.value)}>
                                        <a className="header-menu--menu-item-link" style={{color:'#707070'}}>{item.label}</a>
                                    </li>
                                )}
                            </ul>
                        </nav>

                    </div>
                </div>

            );
        }

    })
);


