import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import BouleanStore from '../../componants/UIStore/BouleanStore';
import DateStore from '../../componants/UIStore/DateStore';
import SelectBoutonStore from '../../componants/UIStore/SelectBoutonStore';
import SelectStore from '../../componants/UIStore/SelectStore';

import { Adresse } from '../../componants/Adresse';

import {InfoContrat} from '../../componants/InfoContrat';
import {choixTypeLocal} from '../../Constantes';

import { observer, inject } from 'mobx-react';

var Peniche = inject("store")(
    observer(({ store }) => {
        const risqueStore = store.risqueStore;

        const styleBlock = { display: 'block', margin: '15px' }
        const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

        const choixTypeResident = [
            { label: "Locataire", value: "locataire" },
            { label: "Propriétaire", value: "proprietaire" }
            //{ label: "Copropriétaire", value: "coproprietaire" }
        ]


        return (
            <div style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'center' }}>{risqueStore.identification}</h1>
                <InfoContrat/>
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                            <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                        </div>
                        <InputStore libelle="Marque de la péniche" champ="marquePeniche" largeur="355px" />
                        <InputStore libelle="Nom de la péniche" champ="nomPeniche" largeur="355px" />
                    </div>
                </div>
                <div style={styleFlex}>
                    <div style={styleBlock}>
                        <Adresse />
                    </div>
                    <div style={styleBlock}>
                        <SelectBoutonStore champ="typeOccupant" multiple={false} choix={choixTypeResident} libelle="Occupant" obligatoire={true} />
                        <div style={{ display: 'flex' }}>                            
                            <InputStore libelle="Valeur de la péniche" champ="valeurPeniche" largeur="100px"   unite="€"  obligatoire={true} />
                            <InputStore libelle="Valeur contenu" champ="valeurContenu" largeur="100px"   unite="€"  obligatoire={true} />                        
                        </div>
                    </div>
                </div>
            </div>
        );

    })
)



export default Peniche;
