import React from "react";
import { inject, observer } from "mobx-react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { api } from "../../api";

//import {Button} from 'primereact/button';

import { libellesType } from "../../Constantes";

const styles = {
    root: {
        padding: 16,
    },
};

export const Attestation = inject("store")(
    observer(
        class Attestation extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    selectedEval: null,
                };
            }

            componentDidMount() {
                this.dt.filter(this.props.store.filtreTypes, "type", "in");
            }

            boutonsAttestations(risque) {
                if(risque.dateFin && new Date(risque.dateFin) < new Date()){
                    return null;
                }
                const attestations = this.props.store.getAttestations(risque).map((attestationId, i) => (
                    <a key={i} className="petitBouton" href={api.getPdfUrl(attestationId + "/" + risque._id)} target="_blank">
                        {this.props.store.getLibelleAttestation(attestationId)}
                    </a>
                ));
                if ((risque.type === "appartement" || risque.type === "residenceSociale") && risque.nbreEnfant) {
                    attestations.push(
                        <a key={100} className="petitBouton" href={api.getPdfUrl("ATT13/" + risque._id)} target="_blank">
                            {risque.nbreEnfant} Att. Scolaire
                        </a>
                    );
                }

                if (risque.nbreLogement ) {
                    attestations.push(
                        <a key={100} className="petitBouton" href={api.getPdfUrl("ATT25/" + risque._id)} target="_blank">
                            {risque.nbreLogement} RC civile vie privée
                        </a>
                    );
                    attestations.push(
                        <a key={100} className="petitBouton" href={api.getPdfUrl("ATT12/" + risque._id)} target="_blank">
                            {risque.nbreLogement} RC civile locative
                        </a>
                    );                          
                }

                if (risque.type === "activite" && risque.occupants && risque.occupants.length > 0) {
                    attestations.push(
                        <a key={100} className="petitBouton" href={api.getPdfUrl("ATT23/" + risque._id + "|toutes")} target="_blank">
                            {risque.occupants.length} Att. Assistance
                        </a>
                    );
                    attestations.push(
                        <a key={101} className="petitBouton" href={api.getPdfUrl("ATT25/" + risque._id + "|toutes")} target="_blank">
                            {risque.occupants.length} Att RC vie privée
                        </a>
                    );
                }

                return <React.Fragment>{attestations}</React.Fragment>;
            }

            boutonsActions(risque) {
                return (
                    <span className="petitBouton" onClick={() => this.props.store.load(risque._id)}>
                        Consulter
                    </span>
                );
            }

            templateAcceptation(rowData, column) {
                let icone = "question-circle";
                let couleur = "black";
                let message = "Attente validation";
                if (rowData.acceptation === true) {
                    icone = "check";
                    couleur = "limegreen";
                    message = "Accepté";
                }
                if (rowData.acceptation === false) {
                    icone = "ban";
                    couleur = "red";
                    message = "Refusé";
                }
                if (rowData.dateFin) {
                    console.log("dateFin", rowData.dateFin);
                }
                /*
            if (rowData.dateFin && new Date(rowData.dateFin) < new Date()) {
                icone = "times-circle";
                couleur = 'red';
                message = 'Résilié';
            }
            */
                return <i className={"pi pi-" + icone} style={{ color: couleur }} title={message} />;
            }

            render() {
                const store = this.props.store;
                const ecart = 100;
                console.log(store.listeRisque.slice());

                const listeRisque = store.listeRisque
                    .slice()
                    .filter((r) => this.props.store.choixAssoAttestation === null || this.props.store.choixAssoAttestation === r.idAssociation); //.filter(r=>!r.resiliation);

                const listeTypes = listeRisque.slice().reduce((acc, t) => {
                    if (!acc.find((tmp) => tmp.value === t.type)) {
                        acc.push({ value: t.type, label: libellesType.get(t.type) });
                    }
                    return acc;
                }, []);

                const typeFilter = (
                    <MultiSelect
                        style={{ width: "100%" }}
                        value={store.filtreTypes}
                        options={listeTypes}
                        onChange={(e) => {
                            this.dt.filter(e.value, "type", "in");
                            store.setFiltreTypes(e.value);
                        }}
                    />
                );

                console.log("attestation-test");
                const widthDate = "110px";

                const styleAsso = store.assoChoisie === "NAT" ? { width: widthDate } : { width: widthDate, display: "none" };

                const associations = [
                    { value: null, label: "-Toutes-" },
                    { value: "NAT", label: "Nationale" },
                    { value: "PNCH", label: "Péniche" },
                    ...store.associations.map((a) => {
                        return {
                            value: a.id,
                            label: a.id + ": " + a.nom,
                        };
                    }),
                ];

                const nbreLocalExploitation = listeRisque.filter((r) => r.type === "localExploitation" && (!r.dateFin || new Date(r.dateFin) > new Date()))
                    .length;
                const nbreAppartement = listeRisque.filter((r) => r.type === "appartement" && (!r.dateFin || new Date(r.dateFin) > new Date())).length;

                return (
                    <div style={styles.root}>
                        {/*<h1>Liste des risques</h1>*/}
                        Association :{" "}
                        <Dropdown
                            value={store.choixAssoAttestation}
                            options={associations}
                            onChange={(e) => store.setChoixAssoAttestation(e.value)}
                            style={{ width: "300px" }}
                        />
                        <span style={{ marginLeft: "50px" }}></span>
                        {store.choixAssoAttestation === "NAT" && (
                            <React.Fragment>
                                <a className="petitBouton" href={api.getPdfUrl("ATT01/NAT")} target="_blank">
                                    RC Générale AN
                                </a>
                                <a className="petitBouton" href={api.getPdfUrl("ATT03/NAT")} target="_blank">
                                    Dommages corporels
                                </a>
                                <a className="petitBouton" href={api.getPdfUrl("att09/tout|" + store.choixAssoAttestation)} target="_blank">
                                    {nbreLocalExploitation > 1 ? "Les " + nbreLocalExploitation : "La"} RC Occupant pour Locaux d'exploitation
                                </a>
                            </React.Fragment>
                        )}
                        {store.choixAssoAttestation === "PNCH" && (
                            <a className="petitBouton" href={api.getPdfUrl("ATT16/PNCH")} target="_blank">
                                RC Générale AN
                            </a>
                        )}
                        {store.choixAssoAttestation !== "PNCH" && store.choixAssoAttestation !== "NAT" && store.choixAssoAttestation !== null && (
                            <React.Fragment>
                                <a className="petitBouton" href={api.getPdfUrl("ATT02/" + store.choixAssoAttestation)} target="_blank">
                                    RC Générale {store.choixAssoAttestation}
                                </a>
                                {nbreLocalExploitation > 0 && (
                                    <a className="petitBouton" href={api.getPdfUrl("att09/tout|" + store.choixAssoAttestation)} target="_blank">
                                        {nbreLocalExploitation > 1 ? "Les " + nbreLocalExploitation : "La"} RC Occupant pour Locaux d'exploitation
                                    </a>
                                )}
                                {nbreAppartement > 0 && (
                                    <React.Fragment>
                                        <a className="petitBouton" href={api.getPdfUrl("att12/tout|" + store.choixAssoAttestation)} target="_blank">
                                            {nbreAppartement > 1 ? "Les " + nbreAppartement : "La"} RC Occupant pour Appartements
                                        </a>
                                        <a className="petitBouton" href={api.getPdfUrl("att13/tout|" + store.choixAssoAttestation)} target="_blank">
                                            Attestations Scolaires
                                        </a>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                        <br />
                        <br />
                        <DataTable value={listeRisque} ref={(el) => (this.dt = el)} paginator={true} rows={10} rowsPerPageOptions={[5, 10, 20]}>
                            <Column field="acceptation" header="Acceptation" sortable={true} style={{ width: widthDate }} body={this.templateAcceptation} />
                            <Column field="idAssociation" header="Asso." sortable={true} style={styleAsso} />
                            <Column
                                field="type"
                                header="Type"
                                sortable={true}
                                filter={true}
                                filterElement={typeFilter}
                                body={(rowData, column) => libellesType.get(rowData.type)}
                            />
                            <Column field="description" header="Description" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column
                                field="dateCreation"
                                style={{ width: widthDate }}
                                header="Date saisie"
                                sortable={true}
                                body={(rowData, column) => (rowData.dateCreation ? new Date(rowData.dateCreation).toLocaleDateString() : "")}
                            />
                            <Column
                                field="dateDebut"
                                style={{ width: widthDate }}
                                header="Début"
                                sortable={true}
                                body={(rowData, column) => (rowData.dateDebut ? new Date(rowData.dateDebut).toLocaleDateString() : "")}
                            />
                            <Column
                                field="dateFin"
                                style={{ width: widthDate }}
                                header="Fin"
                                sortable={true}
                                body={(rowData, column) => (rowData.dateFin ? new Date(rowData.dateFin).toLocaleDateString() : "")}
                            />
                            <Column header="Attestation" body={(rowData, column) => this.boutonsAttestations(rowData)} />
                            <Column style={{ width: "85px" }} header="" body={(rowData, column) => this.boutonsActions(rowData)} />
                        </DataTable>
                    </div>
                );
            }

            handleClick = () => {
                window.alert("En cours de développement...");
            };
        }
    )
);
