import React, { useState } from 'react';

import { api } from '../../api';

export default ({ logements, idRes }) => {

    const styleBlock = { display: 'block', margin: '15px' }
    const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

    const occupants = logements.reduce((accu, logement) => {
        return [
            ...accu,
            ...logement.occupants.map(o => {
                return { ...o, nomPrenom: o.nom + ' ' + o.prenom, noLogement: logement.numero, etage: logement.etage }
            })
        ]
    }, []).sort((a, b) => a.nomPrenom > b.nomPrenom ? 1 : -1)

    return (
        <div style={styleFlex}>
            <ul style={{marginTop:'10px'}}>
                {occupants.map((o, i) => <li key={i}>{o.nomPrenom}&nbsp;
                    {o.enfant &&
                        <a className="petitBouton" href={api.getPdfUrl("ATT13/" + idRes + '|' + o.noLogement + '$' + o.prenom)} target="_blank">Att&nbsp;Scol</a>
                    }
                    {!o.enfant &&
                        <a className="petitBouton" href={api.getPdfUrl("ATT22/" + idRes + '|' + o.nom + ' ' + o.prenom)} target="_blank">RC&nbsp;Villégiature</a>
                    }
                </li>)}
            </ul>
        </div>

    )
}
