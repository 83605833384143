import React from 'react';
import InputStore from '../../componants/UIStore/InputStore';
import DateStore from '../../componants/UIStore/DateStore';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

import { InfoContrat } from '../../componants/InfoContrat';

import { Adresse } from '../../componants/Adresse';



import { observer, inject } from 'mobx-react';

var Appartement = inject("store")(
    observer(class Appartement extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                conjoint: props.store.risqueStore.get('nomConjoint') || props.store.risqueStore.set('prenomConjoint')
            }
        }

        changeConjoint(etat){        
            this.setState({ conjoint: etat });
            if(!etat){
                this.props.store.risqueStore.set('nomConjoint','');
                this.props.store.risqueStore.set('prenomConjoint','');
            }
        }

        render() {
            const store = this.props.store;
            const risqueStore = store.risqueStore;

            const styleBlock = { display: 'block', margin: '15px' }
            const styleFlex = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }

            const styleBlockOccupant = { display: 'block', flexWrap: 'wrap', justifyContent: 'center' }

            const choixTypeResident = [
                { label: "Locataire", value: "locataire" },
                { label: "Propriétaire", value: "proprietaire" }
                //{ label: "Copropriétaire", value: "coproprietaire" }
            ]


            const occupantsArray = risqueStore.get("occupants") ? risqueStore.get("occupants") : [];

            const occupants = occupantsArray.map((occupant, i) => {
                const readOnly = store.user.role === 'lecteur';
                return {
                    index: i,
                    enfant: occupant.enfant,
                    componant:
                        <div key={i} style={styleFlex}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <InputStore libelle="Nom" champ="occupants.nom" index={i} largeur="200px" obligatoire={true} readOnly={readOnly} />
                                <InputStore libelle="Prénom" champ="occupants.prenom" index={i} largeur="200px" obligatoire={true} readOnly={readOnly} />
                                {
                                    occupant.enfant &&
                                    <DateStore champ="occupants.dateNaissance" libelle="Date de naissance" index={i} obligatoire={true} anneeMin={new Date().getFullYear() - 30} anneeMax={new Date().getFullYear() - 2} readOnly={readOnly} />
                                }
                                {!readOnly &&
                                    <span className="petitBouton" style={{ height: '20px', marginTop: '2.2rem' }} onClick={() => risqueStore.removeElement('occupants', i)} >Supprimer</span>
                                }
                            </div>
                        </div>
                }
            })

            const adultes = occupants.filter(o => !o.enfant).map(o => o.componant);
            //console.log("occupants", occupants)
            const enfants = occupants.filter(o => o.enfant).map(o => o.componant);



            return (
                <div style={{ padding: '20px' }}>
                    <h1 style={{ textAlign: 'center' }}>Appartement</h1>
                    <InfoContrat />
                    <div style={styleFlex}>
                        <div style={styleBlock}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <DateStore champ="dateDebut" libelle="Date début" obligatoire={true} />
                                <DateStore champ="dateFin" libelle="Date de fin" obligatoire={false} />
                            </div>
                            <Adresse />
                        </div>





                        <div style={styleBlock}>
                            <InputStore libelle="Superficie" champ="superficie" largeur="100px" unite="m²" regex={/^[0-9]{0,4}$/} />
                            <InputStore libelle="Nbre pièces" champ="nbrePieces" largeur="100px" obligatoire={true} regex={/^[0-9]{0,2}$/} />
                            <InputStore libelle="Valeur contenu" champ="valeurContenu" largeur="100px" unite="€" obligatoire={true} regex={/^[0-9]{0,5}$/} />
                        </div>

                    </div>

                    {store.user.role !== 'lecteur' &&
                        <div style={styleFlex}>
                            <Button label="Ajouter un adulte" icon="pi pi-add" onClick={() => store.risqueStore.addElement('occupants', { prenom: '', nom: '', enfant: false }, 'fin')} />
                            &nbsp;
                            <Button label="Ajouter un enfant" icon="pi pi-add" onClick={() => store.risqueStore.addElement('occupants', { prenom: '', nom: '', enfant: true }, 'fin')} />
                        </div>
                    }

                    {adultes.length > 0 &&
                        <React.Fragment>
                            <div style={styleFlex}>
                                <h3 style={{ marginTop: '2rem' }}>Adultes</h3>
                            </div>
                            {adultes}
                        </React.Fragment>
                    }
                    {enfants.length > 0 &&
                        <React.Fragment>
                            <div style={styleFlex}>
                                <h3 style={{ marginTop: '2rem' }}>Enfants scolarisés à charge de la personne hébergée</h3>
                            </div>
                            {enfants}
                        </React.Fragment>
                    }

                    {store.readOnly && store.user.role !== 'lecteur' &&
                        <div style={styleFlex}>
                            <Button label="Enregistrer les occupants" icon="pi pi-add" onClick={() => store.saveOccupants()} style={{ marginTop: '10px' }} disabled={risqueStore.adultes.length===0} />
                        </div>
                    }
                </div>
            );

        }
    }))


export default Appartement;