import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { observer, inject } from 'mobx-react';


const DateStore = inject("store")(
    observer(class DateStore extends React.Component {

        majValeur(valeur, index) {
            this.props.store.risqueStore.set(this.props.champ, valeur, index);
            if (typeof (this.props.onMaj) === "function") {
                this.props.onMaj(valeur);
            }
        }

        render() {
            const risqueStore = this.props.store.risqueStore;
            const { champ, libelle, obligatoire } = this.props;

            const anneeMax = (this.props.anneeMax) ? this.props.anneeMax : (this.props.store.user.type === "MACIF") ? null : new Date().getFullYear() + 10;
            const anneeMin = (this.props.anneeMin) ? this.props.anneeMin : (this.props.store.user.type === "MACIF") ? null : new Date().getFullYear();


            const fr = {
                firstDayOfWeek: 1,
                dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
                dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
                dayNamesMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
                monthNames: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
                monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"]
            };

            const index = typeof (this.props.index) === "number" ? this.props.index : null;
            const yearRange = anneeMin && anneeMax ? anneeMin.toString() + ":" + anneeMax.toString() : "2000:2030";

            console.log("yearRange", yearRange, this.props.anneeMax, this.props.anneeMin);

            // En raison d'un bug dans primeReact, on est obligé de prendre la veille, puis de l'interdire.
            //const minDate = (champ === "dateDebut" || champ === "dateFin") && this.props.store.user.type !== "MACIF" ? new Date(new Date().setHours(-2, 0, 0)) : null;

            let minDate;

            if (champ === "dateFin") {
                if (risqueStore.get("dateDebut")) {
                    minDate = new Date(risqueStore.get("dateDebut"));
                } else {
                    minDate = this.props.store.user.type !== "MACIF" ? new Date() : null;
                }
            }
            if (champ === "dateDebut") {                
                var today = new Date();                
                minDate = this.props.store.user.type !== "MACIF" ? new Date(today.setDate(today.getDate() - 1)) : null;
            }


            const disabledDates = minDate ? [minDate] : [];
            console.log("minDate",minDate);

            const valeur = risqueStore.get(champ, index);

            if (obligatoire) {
                risqueStore.setChampObligatoire(champ, valeur !== null, index);
            }

            //const disabled = this.props.readOnly === false ? false : this.props.store.readOnly;
            const disabled = (this.props.readOnly === false) ? false : (this.props.champ === "dateFin" && this.props.store.user.type === "RESTO") ? false : this.props.store.readOnly;

            //console.log("disabledDates",disabledDates,"valeur",valeur);

            return (
                <div style={{ display: 'block', margin: '5px' }}>
                    <label for={champ} class="frm_primary_label">{libelle}
                        {obligatoire && <span class="frm_required" style={{ color: valeur ? 'black' : 'red' }}> *</span>}
                        &nbsp;
                        { !disabled && valeur!==null && 
                            <span className="petitBouton" onClick={()=>this.majValeur(null)}>Raz</span>
                        }                        
                    </label>
                    <Calendar id={champ} value={valeur}
                        onChange={(e) => this.majValeur(e.value, index)}
                        locale={fr} dateFormat="dd/mm/yy"                        
                        monthNavigator={true} yearNavigator={true}
                        minDate={minDate}
                        disabledDates={disabledDates}
                        readOnlyInput={true}
                        yearRange={yearRange}
                        //footerTemplate={<div>Footer Content</div>}
                        placeholder={this.props.libelle} disabled={disabled} />

                </div>

            );
        }
    })
)
export default DateStore;
