import React from 'react';

import { observer, inject } from 'mobx-react';
import { Button } from 'primereact/button';
import {Editor} from 'primereact/editor';

export const MajGestRisque = inject("store")(
    observer(({ store }) => store.risqueStore ?
        <React.Fragment>
            {store.risqueStore.composant}            
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <span>{store.risqueStore.acceptation===null ? 'Attente acceptation' : store.risqueStore.acceptation===false ? 'Risque Refusé' : 'Risque Accepté'}</span>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <h4>Commentaire Macif à destination des Restos du coeur</h4>                
            </div>            
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Editor style={{height:'200px'}} value={store.risqueStore.commentaireMacif} onTextChange={(e) =>store.risqueStore.setCommentaireMacif( e.htmlValue)} />
            </div>
            <br/>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Button label="Accepter le risque" icon="pi pi-check" onClick={() => store.accepterRisque()} />
                &nbsp;
                <Button label="Refuser le risque" icon="pi pi-ban"  className="p-button-danger" onClick={() => store.refuserRisque()} />
                {store.risqueStore.id !== null &&
                    <React.Fragment>                        
                        <Button label="Quitter" style={{ marginLeft: '20px' }} icon="pi pi-times" onClick={() => store.goTo('corbeille')} />
                    </React.Fragment>
                }
                {store.risqueStore.id === null &&                    
                    <Button label="Annuler" style={{ marginLeft: '20px' }} icon="pi pi-times" onClick={() => store.goTo('assurer')} />                    
                }                
        </div>
        </React.Fragment>
        : null
    )
)


//export default SaisieRisque;
