import React from 'react';
import { inject,observer } from 'mobx-react';

import {Users} from './Users';


const styles = {
    root: {
        padding: 16
    }
};







export const Admin = inject('store')(
    observer(class Admin extends React.Component {    
        
        constructor(props) {
            super(props);
        }


        render() {
            const store = this.props.store;


            return (
                <div style={styles.root} style={{textAlign:'center'}}> 
                   <Users />                   
                </div>                                    
            );
        }

    })
);