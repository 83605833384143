import React from 'react';
import { inject,observer } from 'mobx-react';

import LogoMacif from './macif.svg';

import './style.css';

const styles = {
    root: {
        padding: 16
    }
};



export const Logo = inject('store')(    
    observer(class extends React.Component {
        render() {
            const store = this.props.store;

            return (
                <div className="header--inner" data-color="#3cb5a3"  style={{top:'-15px'}}>
                    <div className="row large-collapse">
                        <div className="columns expand small-12 medium-4 large-3">
                            <div className="logo">
                                <a href="/" className="custom-logo-link" rel="home" itemprop="url">
                                    <img src="https://www.restosducoeur.org/wp-content/uploads/2016/04/logo.svg" className="custom-logo-resto" alt="Les Restos du Cœur" itemprop="logo" />
                                </a>
                                <h1 className="hide">Les restos du coeur - les relais du coeur</h1>
                            </div>
                        </div>
                        <div className="columns expand small-12 medium-4 large-6" style={{ textAlign: 'center' }}>
                            { store.titreAssociation &&
                                <div className="nom-association">
                                    <a href="#" className="widget-header--circle-link t-family-din t-uppercase">{store.titreAssociation}</a>
                                </div>
                            }
                        </div>
                        <div className="columns expand small-12 medium-4 large-3">
                            <div className="logo">
                                <a href="/" className="custom-logo-link" rel="home" itemprop="url">
                                    <img src={LogoMacif} className="custom-logo-macif" alt="Les Restos du Cœur" itemprop="logo" />
                                </a>
                                <h1 className="hide">Les restos du coeur - les relais du coeur</h1>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    })
);


